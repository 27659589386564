import React from 'react';

export const defaultUserContext = {
  theme: 'light',
  username: 'Guest',
  firstName: '',
  lastName: '',
  roles: [],
  version: '',
  newMessages: 0,
  updateUser: () => {
  },
  updateTheme: () => {
  },
  updateVersion: () => {
  },
  /**
   * @param {{type: "error"|"warning"|"info"|"success", message: string, buttonText: string, buttonOnClick: function}} data
   */
  enqueueMessage: (data) => {
  },
};

export const getUserContext = () => {
  const context = {...defaultUserContext};
  const json = localStorage.getItem('userContext');
  context.theme = localStorage.getItem('theme') || defaultUserContext.theme;
  context.version = localStorage.getItem('version');
  context.newMessages = localStorage.getItem('newMessages');

  if (json) {
    Object.assign(context, JSON.parse(json));
  }
  return context;
}

export const UserContext = React.createContext(defaultUserContext);

export const OntologyContext = React.createContext({});

export const ConsoleContext = React.createContext({
  defaultNavigationOpen: null,
  setDefaultNavigationOpen: () => {
  }
});
