import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { getProperty, setProperty, deepAssign, shallowCompare } from "../index";
import { TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { MultiSelectField } from "../../components/common";
import { OntologyContext } from "../../context";

const useStyles = makeStyles({
  textFieldInitialColor: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'initial',
      '-webkit-text-fill-color': 'initial'
    }
  },
  autoCompleteInitialColor: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiChip-root.Mui-disabled': {
      opacity: 'initial',
      '-webkit-text-fill-color': 'initial'
    },
    '& .MuiChip-deleteIcon': {
      display: 'none'
    }
  }
});

/**
 *  Custom React hook.
 *  Isolates inputs rendering and provide validation of inputs `onBlur`.
 *  `checkAll()` is exported for manual check of the form.
 * @example
 *   const [inputComponents, checkAll] = useInputs(inputs, state, errors, handleChange);
 * @param inputs
 * @param {{}|function(): Promise<{}>} initialFormData
 * @param errorsOverride
 * @returns {[{}, function(): boolean]}
 */
export function useInputs(inputs, initialFormData, errorsOverride = {}) {
  const classes = useStyles();
  // console.log('----render-----')
  // const time = Date.now();
  // console.log(initialFormData)
  const mounted = useRef(true);
  const [errors, setErrors] = useState({});
  const forceRender = useCallback(() => {
    if (mounted.current) setErrors(errors => ({...errors}));
  }, []);

  // Cache requests. If multiple inputs share the same option request, we only request once.
  const ontologyContext = useContext(OntologyContext);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  });

  // We are not going to mutate the state. So, we don't want the setState function.
  const [state] = useState(() => {
    const state = {};
    for (const [name, input] of Object.entries(inputs)) {
      let defaultValue = input.value || '';
      if (input.component === MultiSelectField && input.multiple !== false)
        defaultValue = [];
      setProperty(state, name, defaultValue);
    }
    return state;
  });

  const inputComponents = useMemo(() => ({}), []);

  useEffect(() => {
    if (initialFormData == null) return;
    if (typeof initialFormData === "function") {
      initialFormData().then(data => deepAssign(state, data));
    } else {
      deepAssign(state, initialFormData);
    }

    // reset current inputComponents since default value changed.
    Object.keys(inputComponents).forEach(key => delete inputComponents[key]);

    forceRender();
  }, [inputComponents, initialFormData, state, forceRender]);

  const handleChange = name => e => {
    const {value} = e.target;
    setProperty(state, name, value);
    forceRender();
    // console.log(state)
  }

  /**
   * Call the field's validator.
   */
  const callValidator = useCallback((fn, params) => {
    // if validator does not exist, return true (passed validator)
    if (!fn) return true;
    const args = [];
    for (const param of params)
      args.push(getProperty(state, param))
    return fn(...args)
  }, [state]);

  /**
   * Return true if success
   * @param {string} name - The field path.
   * @param {boolean} required - Is this field mandatory.
   * @param {*} validatorResult - The validation result from the validator
   * @param {undefined|[]} checkAfterSuccess - Also check the given fields after success checking the current one.
   */
  const checkOne = useCallback((name, required, validatorResult, checkAfterSuccess) => {
    // console.log('!', state, name)
    const currValue = getProperty(state, name);
    const {component} = inputs[name];
    if (required && (currValue === '' ||
        // Date component null means empty
      (component && component.name === 'DateField' && currValue == null)
    )) {
      setErrors(errors => ({...errors, [name]: 'This field is required'}));
    } else if (validatorResult !== true) {
      setErrors(errors => ({...errors, [name]: validatorResult}));
    } else {
      setErrors(errors => ({...errors, [name]: ''}));

      let success = true;

      if (checkAfterSuccess) {
        for (const nameToCheck of checkAfterSuccess) {
          const {required, validator, validatorParams, checkAfterSuccess} = inputs[nameToCheck];
          // supports recursive checking
          if (!checkOne(nameToCheck, required, callValidator(validator, validatorParams), checkAfterSuccess))
            success = false;
        }
      }
      return success;
    }
  }, [callValidator, inputs, state]);

  const checkAll = () => {
    let success = true;
    for (const [name, item] of Object.entries(inputs)) {
      const {required = true, validator, validatorParams = [name]} = item;
      if (!checkOne(name, required, callValidator(validator, validatorParams)))
        success = false;
    }
    console.log(errors)
    return success;
  };

  // We are building our own React.memo logic, since React.useMemo hook cannot be used inside a loop.
  for (const [name, item] of Object.entries(inputs)) {
    const {
      label, required = true, helperText, validator, validatorParams = [name],
      component: ReactComponent = TextField, checkAfterSuccess, hidden = false, ...props
    } = item;

    if (hidden) continue;

    // Get options from async function
    if (props.options && typeof props.options === 'function') {
      // store the function in `temp`
      const temp = props.options;
      props.options = [];
      (async function () {
        inputs[name].options = await ontologyContext.request(temp)
        // console.log(`Got options for ${name}, requesting rerender.`)
        if (mounted.current)
          forceRender();
      })()
    }

    // there could be more dynamic props, make sure to include it here.
    // i.e. the props updated by this useInputs hook.
    const dynamicProps = {
      error: !!errorsOverride[name] || !!errors[name],
      helperText: errorsOverride[name] || errors[name] || helperText,
      ...props,
      value: getProperty(state, name),
    }

    // Custom style for not Editable, display values only
    if (dynamicProps.notEditable) {
      // Specific style for TextField
      if (ReactComponent === TextField || ReactComponent.name === 'PhoneInput') {
        dynamicProps.disabled = true;
        dynamicProps.className = classes.textFieldInitialColor;
        dynamicProps.InputLabelProps = {shrink: true};
      }

      // Specific style for AutoComplete
      else if (ReactComponent === MultiSelectField) {
        dynamicProps.disabled = true;
        dynamicProps.className = classes.autoCompleteInitialColor;
        dynamicProps.TextFieldProps = {InputLabelProps: {shrink: true}};
      }

    }
    delete dynamicProps.notEditable;

    // console.log(dynamicProps)
    // If this component is previously rendered, we check if the props inside the component changed.
    const currComponent = inputComponents[name];
    if (currComponent) {
      // defaultValue should not change
      dynamicProps.defaultValue = currComponent.props.defaultValue;
      // Here, we only listen changes of dynamicProps
      if (!shallowCompare(dynamicProps, currComponent.props)) {
        // console.log('Rerender', name)
        inputComponents[name] = React.cloneElement(inputComponents[name], {
          ...dynamicProps
        });
      }
    } else {
      // For first time rendering the components, we store it into `inputComponents`
      // console.log(`Render ${name}`)
      inputComponents[name] =
        <ReactComponent
          fullWidth
          variant="outlined"
          label={label}
          onChange={handleChange(name)}
          required={required}
          onBlur={() => checkOne(name, required, callValidator(validator, validatorParams), checkAfterSuccess)}
          {...dynamicProps}
        />
    }
  }
  // console.log(`----end render---- ${Date.now() - time}ms`);
  return [inputComponents, state, checkAll, forceRender];
}
