import { Box, Link, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Image } from "../index";
import React from "react";

const useStyles = makeStyles(theme => ({
  img: {
    top: '50%',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    maxWidth: 200,
    maxHeight: 250,
    objectFit: 'cover',
  },
  description: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  },
}));

export function ReactInfoWindow({marker, onMouseEnter}) {
  const classes = useStyles();
  const {title, content, onClickTitle, img} = marker;
  return (
    <Box sx={{maxWidth: 400, zIndex: 100}} onMouseEnter={onMouseEnter}>
      {img && <Image id={img} alt="Preview" onClick={onClickTitle}
                     className={classes.img}/>}
      <Link style={{cursor: 'pointer'}} onClick={onClickTitle}>
        <Typography>
          {title}
        </Typography>
      </Link>
      <Typography color="textSecondary" className={classes.description}>
        {content}
      </Typography>
    </Box>
  );
}
