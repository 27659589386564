import { useParams } from 'react-router-dom';
import OrganizationPopup from "../OrganizationPopup";
import {
  Typography,
  Paper,
  Container,
  ImageList,
  ImageListItem,
  Divider,
  Chip,
  Grid,
  Button,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { formatFullAddress, formatSiteAddress, formatSiteArea } from "../../../../../utils/data/formatter";
import { downloadFile } from "../../../../../api/upload";
import React, { useContext, useEffect, useState } from "react";
import {
  addOpportunityToFavourites,
  getListing,
  isFavouriteOpportunity,
  removeOpportunityFromFavourites
} from "../../../../../api/partnership";
import { cache } from "../../../../../utils/cache/memoryCache";
import { server } from "../../../../../config";
import { getLandUseTypes, getProjectTypes, getSpaceTypes } from "../../../../../api/project";
import { getPartnershipRoles } from "../../../../../api/partnership/partnershipRole";
import { OntologyContext, ConsoleContext } from "../../../../../context";
import { ImageViewer, Loading, SubmitButton } from "../../../../common";
import { FileList } from "../../../../common/FileList";
import { Star, EmailArrowRightOutline, StarOutline } from "mdi-material-ui";
import SubmitInterestDialog from "../../Message/SubmitInterest";


const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    // color: theme.palette.mode === 'light' && 'rgb(0,68,171)',
    fontWeight: 400,
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 18,
    marginBottom: 20,
    whiteSpace: 'pre-line',
    overflow: "auto",
    overflowWrap: 'break-word'
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
  },
  dataTitle: {
    fontWeight: 500,
    marginRight: 8,
  },
  img: {
    cursor: "pointer",
  },
  contactButton: {
    color: '#3b924a',
    border: '1px solid #3b924a',
    '&:hover': {
      backgroundColor: '#c4efc4',
      border: '1px solid #3b924a',
    },
  },
  favouriteButton: {
    color: '#bf8a09',
    border: '1px solid #fab818',
    '&:hover': {
      backgroundColor: '#efdec4',
      border: '1px solid #fab818',
    },
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  gridContainer: {
    display: 'flex',
  },
  gridLeft: {
    position: "relative",
    width: '66.66%',
  },
  gridRight: {
    marginLeft: 24,
    position: "relative",
    width: '33.33%',
  },
  floatingPaper: {
    borderRadius: 10,
    padding: 16,
    position: 'sticky',
    top: '12vh',
  }
}));


export function getDataDisplay(classes, opportunity, categories) {
  const {
    designated_land_uses = [], project_types = [],
    site_area, site_area_unit, spaces_offered = [], partnership_roles_required = [], files = [], organization = {},
    modified_at, other_site_addresses = [], missions, core_values // current_land_uses = [], proposed_land_uses = [],
  } = opportunity;
  const {projectTypes, landUseTypes, spaceTypes, partnershipRoles} = categories;
  return {
    Organization: {
      hide: !organization.name,
      render: () => <OrganizationPopup name={organization.name}
                                       types={organization.organization_types}>
        <Typography variant="body1">
          {organization.name}
        </Typography>
      </OrganizationPopup>
    },
    'Other Site Addresses': {
      hide: other_site_addresses.length === 0,
      render: () => <Typography style={{whiteSpace: 'pre'}}>
        {other_site_addresses.map(address => formatFullAddress(address)).join('\n')}
      </Typography>
    },
    'Partnership Roles required': {
      hide: partnership_roles_required.length === 0,
      render: <div className={classes.chipContainer}>
        {partnership_roles_required.map(type =>
          <Chip key={type} label={partnershipRoles[type]}/>)}
      </div>
    },
    'Site size': {
      hide: !site_area,
      render: formatSiteArea(site_area, site_area_unit)
    },
    'Project type': {
      hide: project_types.length === 0,
      render: <div className={classes.chipContainer}>
        {project_types.map(type =>
          <Chip key={type} label={projectTypes[type]}/>)}
      </div>
    },
    // 'Current land use': {
    //   hide: current_land_uses.length === 0,
    //   render: current_land_uses.map(type => landUseTypes[type]).join(', ')
    // },
    // 'Proposed land use': {
    //   hide: proposed_land_uses.length === 0,
    //   render: proposed_land_uses.map(type => landUseTypes[type]).join(', ')
    // },
    'Designated land use': {
      hide: designated_land_uses.length === 0,
      render: <div className={classes.chipContainer}>
        {designated_land_uses.map(type =>
          <Chip key={type} label={landUseTypes[type]}/>)}
      </div>
    },
    'Space Offered': {
      hide: spaces_offered.length === 0,
      render: <div className={classes.chipContainer}>
        {spaces_offered.map(type =>
          <Chip key={type} label={spaceTypes[type]}/>)}
      </div>
    },
    'Updated at': {
      hide: modified_at == null,
      render: () => new Date(modified_at).toLocaleString()
    },
    'd1': {
      hide: !missions && !core_values,
      render: 'divider',
    },
    'Missions': {
      hide: !missions,
      render: missions,
    },
    'Core values': {
      hide: !core_values,
      render: core_values,
    },
    'd2': {
      hide: files.length === 0,
      render: 'divider',
    },
    'Resources': {
      hide: files.length === 0,
      hideTitle: true,
      // parentStyle: {display: 'initial'},
      render: (
        <>
          <Typography variant={"h5"} gutterBottom>Resources</Typography>
          <FileList files={files} onClick={(id, filename) => downloadFile(id, filename)}/>
        </>
      )
    }
  }
}


export default function OpportunityListingDetail() {
  const classes = useStyles();
  // Close the navigation drawer by default
  const consoleContext = useContext(ConsoleContext);

  const ontologyContext = useContext(OntologyContext);
  const {id} = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [categories, setCategories] = useState(null);
  const [opportunity, setOpportunity] = useState(null);

  const [pendingFavourite, setPendingFavourite] = useState(true);
  const [isFavourite, setIsFavourite] = useState(false);

  const [imageViewer, setImageViewer] = useState({
    open: false,
    images: [],
    current: 0,
  });

  useEffect(() => {
    consoleContext.setDefaultNavigationOpen(false);
  }, [consoleContext]);

  useEffect(() => {
    // load when id is given
    if (id) {
      getListing(id).then(data => {
        setOpportunity(data);
        setImageViewer(state => ({
          ...state,
          images: (data.images || []).map(image => ({
            url: cache.has(image.id) ? cache.get(image.id) : `${server.address}/api/upload/${image.id}`,
            name: image.filename
          }))
        }))
      });

      isFavouriteOpportunity(id).then(({result}) => {
        setIsFavourite(result);
        setPendingFavourite(false);
      })

    }
    return () => setImageViewer({
      open: false,
      images: [],
      current: 0,
    });
  }, [id]);

  useEffect(() => {
    (async function () {
      const newCategories = {};
      newCategories.spaceTypes = await ontologyContext.request(getSpaceTypes);
      newCategories.partnershipRoles = await ontologyContext.request(getPartnershipRoles);
      newCategories.projectTypes = await ontologyContext.request(getProjectTypes);
      newCategories.landUseTypes = await ontologyContext.request(getLandUseTypes);
      setCategories(newCategories);
    })();
  }, [ontologyContext]);

  const handleClickAddToFavourite = () => {
    setPendingFavourite(true);
    if (isFavourite) {
      removeOpportunityFromFavourites(id).then(() => {
        setIsFavourite(false);
        setPendingFavourite(false);
      })
    } else {
      addOpportunityToFavourites(id).then(() => {
        setIsFavourite(true);
        setPendingFavourite(false);
      })
    }
  }

  if (!opportunity || !categories)
    return <Loading message="Loading Details.."/>;

  const {
    site_address = {}, brief_description, images = [],
  } = opportunity;

  const dataDisplay = getDataDisplay(classes, opportunity, categories);


  return <Container>
    {/*<Paper style={{padding: 24}}>*/}
    <Typography variant="h4" className={classes.title}>
      {formatSiteAddress(site_address)}
    </Typography>

    {images.length > 0 &&
    <ImageList variant={"quilted"} cols={3} spacing={12} style={{
      gridTemplateColumns: 'repeat(6, calc(50% - 40px))',
      gridTemplateRows: 'minmax(300px, 40vh)',
    }}>
      {images.map(({filename, id}, idx) => {
        return (
          <ImageListItem key={idx} style={{gridRow: 1}}>
            <img className={classes.img} src={cache.has(id) ? cache.get(id) : `${server.address}/api/upload/${id}`}
                 alt={filename} onClick={() => {
              setImageViewer(state => ({
                ...state,
                open: true,
                current: idx,
              }));
            }}/>
          </ImageListItem>
        )
      })}
    </ImageList>}

    <ImageViewer
      {...imageViewer}
      onClose={() => setImageViewer(state => ({...state, open: false}))}
    />

    <Divider className={classes.divider}/>


    <div className={classes.gridContainer}>
      <div className={classes.gridLeft}>
        <Typography variant="body1" className={classes.subTitle}>
          {brief_description || 'No descriptions.'}
        </Typography>

        <Divider className={classes.divider}/>

        {Object.entries(dataDisplay).map(([title, config = {}]) => {
          let {hide, render, parentStyle, hideTitle} = config;
          if (hide) return null;
          if (render === 'divider') return <Divider className={classes.divider}/>;
          if (typeof render === "function")
            render = render();
          if (hideTitle)
            return render;
          return (
            <div key={title} className={parentStyle ? parentStyle : classes.dataContainer}>
              <Typography variant="body1" className={classes.dataTitle}>{title}: </Typography>
              {typeof render === "string" ? <Typography variant="body1">{render}</Typography> : render}
            </div>
          );
        })}
      </div>
      <div className={classes.gridRight}>
        <Paper elevation={5} className={classes.floatingPaper}>
          <Typography variant="h6" gutterBottom>
            Interested to {formatSiteAddress(site_address)}?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SubmitButton
                noDefaultStyle
                loading={pendingFavourite}
                startIcon={isFavourite ? <Star/> : <StarOutline/>}
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.favouriteButton}
                onClick={handleClickAddToFavourite}
              >
                {isFavourite ? 'Added to Favourite' : 'Add to Favourite'}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<EmailArrowRightOutline/>}
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.contactButton}
                onClick={() => setOpenDialog(true)}
              >
                Contact
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
    <SubmitInterestDialog id={id} open={openDialog} projectTypes={categories.projectTypes}
                          landUseTypes={categories.landUseTypes}
                          onClose={() => setOpenDialog(false)}/>
  </Container>;
}
