export const server = {
  // address: 'http://64.52.29.182:5000',
  address: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000',
};

const rawVersion = localStorage.getItem('version') || 0;
const version = new Date(Number.parseInt(rawVersion) * 1000).toLocaleString();
const print = `
╔═╗┌─┐┬─┐┌─┐┌─┐┬  
╠═╝├─┤├┬┘│  ├┤ │  
╩  ┴ ┴┴└─└─┘└─┘┴─┘
Server: ${server.address}
Frontend Version: ${version} (${rawVersion})
Current Time: ${new Date().toLocaleString()}`
console.log(print.slice(1));
