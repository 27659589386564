// Mount a react component into uncontrolled component
import { render } from "react-dom";
import { ReactInfoWindow } from "./ReactInfoWindow";
import React from "react";
import { server } from "../../../config";
import { getJson } from "../../../api";
import StylesProvider from '@mui/styles/StylesProvider';

/**
 * @type {google.maps}
 */
const Maps = window.google?.maps || {};
const {InfoWindow, Size} = Maps;

export function createInfoWindow(map, markerElement, marker, history, idx, onMouseEnter) {
  const infoWindow = new InfoWindow({
    content: `<div id="infoWindow-${idx}" >Loading...</div>`,
    position: marker.position,
    pixelOffset: new Size(0, -42),
    disableAutoPan: true,
  });
  infoWindow.addListener('domready', e => {
    render(<StylesProvider injectFirst>
        <ReactInfoWindow history={history} marker={marker} onMouseEnter={onMouseEnter}/>
      </StylesProvider>,
      document.getElementById(`infoWindow-${idx}`))
  });
  // 'clicked' and 'show' act as an instance variable.
  let clicked = false, show = false;
  document.querySelector(`infoWindow-${idx} button`);
  markerElement.addListener('mouseover', e => {
    infoWindow.open(map);
    show = true;
    markerElement.addListener('mouseout', e => {
      show && !clicked && infoWindow.close();
      if (show) show = false;
    });
    markerElement.addListener('click', e => {
      clicked = true;
      infoWindow.open(map);
    });
    infoWindow.addListener('closeclick', e => {
      clicked = false;
    });
  });
  infoWindow.setZIndex(200);
  return infoWindow;
}

/**
 * Load GeoJSONs.
 * @param {google.maps.Map} map - Google Map instance
 * @return {Promise<void>}
 */
export async function loadGeoJson(map) {
  map.data.setStyle({fillOpacity: 0, strokeWeight: 1, strokeOpacity: 0.6,});

  // load geojson metadata
  const metadata = await getJson(`/api/map/metadata.json`);
  for (const [province, cities] of Object.entries(metadata)) {
    cities.forEach(city => map.data.loadGeoJson(`${server.address}/api/map/reduced/${province}/${city}.geojson`));
  }
}
