import React, { useCallback, useContext, useRef, useMemo, useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {
  Typography, Drawer, AppBar, Toolbar, List, Divider, IconButton, Collapse,
  ListItem, ListItemIcon, ListItemText, CssBaseline, Button,
  useMediaQuery, useTheme
} from "@mui/material";
import {
  Menu as MenuIcon, MenuOpen as MenuOpenIcon, ExpandLess, ExpandMore
} from '@mui/icons-material'
import { navConfig } from "../../../routes/navConfig";
import { UserContext } from "../../../context";
import { logOut } from "../../../api/auth";
import { Loading } from "../../common";
import { ConsoleContext } from '../../../context';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    opacity: 0,
    overflow: 'hidden',
    height: 0
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, // hide on sm
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  headerItem: {
    flexGrow: 1
  },
  headerButton: {
    color: 'white',
    marginRight: 5,
  },
  navTopSpace: {
    justifyContent: 'left',
    backgroundColor: theme.palette.primary.contrastText
  },
  navButton: {
    height: 42,
    fontSize: 15,
    color: '#496169',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'rgb(228,234,246)',
    },
    '&.Mui-selected': {
      color: '#5850ec',
      fontWeight: 600,
      backgroundColor: 'rgb(220,226,241)',
    },
  },
  navTitle: {
    '&:hover': {
      backgroundColor: 'rgb(234,234,234)',
    },
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: 40,
    color: 'inherit'
  }
}));

export default function Console({children}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const theme = useTheme();
  const isUpLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(isUpLarge);

  const {current: consoleContext} = useRef({
    defaultNavigationOpen: null,
    /**
     * Change the default value for `open`.
     */
    setDefaultNavigationOpen: (newState) => {
      if (consoleContext.defaultNavigationOpen !== newState) {
        consoleContext.defaultNavigationOpen = newState;
        setOpen(newState);
      }
    }
  });

  const [expanded, setExpanded] = useState(
    navConfig.filter(item => !item.hide
      || item.children.filter(child => child.href === location.pathname).length > 0
    ).map(item => item.label)
  );

  // Auto open or close drawer when screen is resized.
  useEffect(() => {
    // Only open the drawer if `defaultNavigationOpen` is not set.
    if (consoleContext.defaultNavigationOpen === null)
      setOpen(isUpLarge);

  }, [isUpLarge, consoleContext.defaultNavigationOpen]);

  const handleLogOut = async () => {
    await logOut();
    userContext.updateUser({username: 'Guest'});
    navigate('/login');
  }

  const handleClickExpand = useCallback((id) => () => {
    const idx = expanded.indexOf(id);

    if (idx !== -1) {
      setExpanded(expanded => {
        expanded.splice(idx, 1);
        return [...expanded]
      });
    } else {
      setExpanded(expanded => [...expanded, id]);
    }
  }, [expanded]);

  const menus = useMemo(() => {
    const components = [];
    for (const {type = 'menu', label, children, icon: Icon} of navConfig) {
      const open = expanded.includes(label);

      if (type === 'title') {
        components.push(
          <ListItem key={label} button onClick={handleClickExpand(label)}
                    className={clsx(classes.navButton, classes.navTitle)}>
            {Icon && <ListItemIcon className={classes.listItemIcon}>
              <Icon/>
            </ListItemIcon>}
            <ListItemText primary={label}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
        )
      }
      if (children) {
        components.push(
          <Collapse in={open} timeout="auto" unmountOnExit key={label + 'list'}>
            <List component="div" disablePadding>
              {children.map(({icon, label, href}, idx) => {
                const Icon = icon;
                return (
                  <ListItem selected={href === location.pathname} button disabled={!href} key={idx}
                            className={clsx(classes.navButton, classes.nested)}
                            onClick={() => {
                              if (!isUpLarge) setOpen(false);
                              navigate(href)
                            }}>
                    {Icon && <ListItemIcon className={classes.listItemIcon}>
                      <Icon/>
                    </ListItemIcon>}
                    <ListItemText disableTypography primary={label}/>
                  </ListItem>
                )
              })}
            </List>
            <Divider/>
          </Collapse>
        )
      }
    }
    return (
      <List>
        {components}
      </List>
    );
  }, [classes, location, navigate, isUpLarge, expanded, handleClickExpand]);

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar
        // variant="outlined"
        position="fixed"
        className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => setOpen(!open)}
            className={classes.menuButton}
            size="large">
            {open ? <MenuOpenIcon/> : <MenuIcon/>}
          </IconButton>

          <Typography variant="h6" noWrap className={classes.headerItem}>

          </Typography>

          {/*<IconButton color="inherit" onClick={() => userContext.updateTheme(userContext.theme === 'light' ? 'dark' : 'light')}>*/}
          {/*  {userContext.theme === 'light' ? <DarkThemeIcon/> : <LightThemeIcon/>}*/}
          {/*</IconButton>*/}

          <Button className={classes.headerButton} onClick={() => navigate('/')}>
            Home Page
          </Button>
          <Button className={classes.headerButton} onClick={handleLogOut}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isUpLarge ? "permanent" : "temporary"}
        open={open}
        onClose={() => setOpen(false)}
        className={clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={clsx(classes.toolbar, classes.navTopSpace)}>
          <Typography color="textSecondary" variant="h5" style={{paddingLeft: 10, fontWeight: 500}}>Parcel</Typography>
        </div>
        {/*<Typography color="textPrimary">*/}
        {/*  Hello, {userContext.username}*/}
        {/*</Typography>*/}

        {menus}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar}/>
        {
          useMemo(() => (
            <ConsoleContext.Provider value={consoleContext}>
              <React.Suspense fallback={<Loading/>}>
                {children}
              </React.Suspense>
            </ConsoleContext.Provider>
          ), [consoleContext, children])
        }

      </main>
    </div>
  );
}
