import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Loading, OkDialog } from "../../../common";
import { useNotificationOfInterestForm } from "../../../forms/notificationOfInterest";
import { sendInterest } from "../../../../api/notification";
import { formatSiteAddress } from "../../../../utils/data/formatter";
import { getDataDisplay } from "../Opportunity/OpportunityDialog";
import { OntologyContext } from "../../../../context";
import { getSpaceTypes } from "../../../../api/project";
import { getPartnershipRoles } from "../../../../api/partnership/partnershipRole";
import { getListing } from "../../../../api/partnership";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    color: theme.palette.mode === 'light' && 'rgb(0,68,171)',
    fontWeight: 400,
    marginBottom: 10,
  },
  instruction: {
    color: theme.palette.mode === 'light' && 'rgb(0,68,171)',
    marginBottom: 20,
    fontWeight: 550,
    fontSize: 17,
  },
  detailsText: {
    fontWeight: 550,
    fontSize: 17,
    color: 'rgb(21,102,206)',
  },
  dataTitle: {
    fontWeight: 500,
    marginRight: 8,
  },
  dataContainer: {
    display: 'flex'
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  accordionDetails: {
    display: 'block'
  },
  description: {
    marginBottom: 20,
    whiteSpace: 'pre-line',
    // maxHeight: 300,
    overflow: "auto",
    overflowWrap: 'break-word'
  }
}));

export default function SubmitInterestDialog({id, open, onClose, projectTypes, landUseTypes}) {
  const classes = useStyles();
  const ontologyContext = useContext(OntologyContext);

  const [opportunity, setOpportunity] = useState(null);
  const [initialValue] = useState({});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [spaceTypes, setSpaceTypes] = useState({});
  const [partnershipRoles, setPartnershipRoles] = useState({});

  useEffect(() => {
    // Do nothing when active = false;
    let active = true;

    // clear data first
    setOpportunity(null);
    if (id)
      getListing(id).then(data => {
        active && setOpportunity(data);
      });
    ontologyContext.request(getSpaceTypes).then(data => active && setSpaceTypes(data));
    ontologyContext.request(getPartnershipRoles).then(data => active && setPartnershipRoles(data));

    return () => {
      active = false;
    }
  }, [id, ontologyContext]);

  const [inputComponents, form, checkAll]
    = useNotificationOfInterestForm({mode: 'create', formData: initialValue});

  const submit = async () => {
    console.log(form);
    if (checkAll()) {
      setWaiting(true);
      const res = await sendInterest(id, form);
      setWaiting(false);
      setDialogOpen(true);
      console.log(res);
    }
  };

  if (!opportunity)
    return (
      <Dialog open={open} onClose={onClose} maxWidth={"md"}>
        <DialogContent>
          <Loading message="Loading Details.."/>
        </DialogContent>
      </Dialog>
    )

  const {site_address = {}, brief_description} = opportunity;

  const dataDisplay = getDataDisplay({
    opportunity, projectTypes, landUseTypes, spaceTypes, partnershipRoles
  });

  // Hide resources
  delete dataDisplay.Resources;

  const descriptionComponent = brief_description && <div key="description" className={classes.dataContainer}>
    <Typography variant="body1" className={classes.dataTitle}>Description: </Typography>
    <Typography variant="body1" className={classes.description}>
      {brief_description}
    </Typography>
  </div>

  let details = Object.entries(dataDisplay).map(([title, {hide, render, parentStyle}]) => {
    if (hide) return null;
    if (typeof render === "function")
      render = render();
    return (
      <div key={title} className={parentStyle ? parentStyle : classes.dataContainer}>
        <Typography variant="body1" className={classes.dataTitle}>{title}: </Typography>
        {typeof render === "string" ? <Typography variant="body1">{render}</Typography> : render}
      </div>
    );
  });

  details = [details[0], descriptionComponent, ...details.slice(1)];

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogContent>
        <Typography variant="h4" className={classes.title}>
          Notice of Interest for {formatSiteAddress(site_address)}
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
          >
            <Typography variant="body1" className={classes.detailsText}>Show Opportunity Details</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {details}
          </AccordionDetails>
        </Accordion>


        <Divider className={classes.divider}/>
        <Typography variant="body1" className={classes.instruction}>
          To submit an expression of interest for this listing, please complete the following fields:
        </Typography>
        {inputComponents}


        <OkDialog
          open={dialogOpen}
          handleClose={() => {
            setDialogOpen(false);
            onClose();
          }}
          title="Success!"
          message="The submission of your notification of interest was successful."
        />
      </DialogContent>
      <DialogActions>

        <Button variant="contained" color="primary" className={classes.button} onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" color="secondary" className={classes.button} onClick={submit} disabled={waiting}>
          Submit
        </Button>
      </DialogActions>

    </Dialog>
  )
}
