export class LRUCache {
  constructor() {
    this.length = 0;
    this.size = 0;
    this.maxSize = 50 * 1024 * 1024; // 50 MB
    this.storage = new Map();

    // Last element is the most recently used
    this.lruList = [];
  }

  set(key, val, size = 0) {
    // Skip large files
    if (size > this.maxSize) return;

    // Remove one or more entries if new size exceeds maxSize
    while (this.size + size > this.maxSize) {
      const {key, size: removedSize} = this.lruList.shift()
      this.storage.delete(key);
      this.size -= removedSize;
      this.length--;
    }

    this.size += size;
    this.length++;
    this.storage.set(key, val);
    this.lruList.push({key, size});
  }

  get(key) {
    const index = this.lruList.findIndex(entry => entry.key === key);
    if (index === -1) return;
    // move it to the end of the lru list
    this.lruList.push(...this.lruList.splice(index, 1));
    return this.storage.get(key);
  }

  has(key) {
    return this.storage.has(key);
  }
}

export const cache = new LRUCache();
