import { getJson, postJson } from "../index";

export const sendInterest = async (id, data) => {
  return await postJson(`/api/interests/${id}`, data);
};

export const getSentNotifications = async () => {
  return await getJson('/api/interests/sent');
};

export const getReceivedNotifications = async () => {
  return await getJson('/api/interests/received');
};

export const getSentNotificationById = async (id) => {
  return await getJson('/api/interests/sent/' + id);
}

export const getReceivedNotificationById = async (id) => {
  return await getJson('/api/interests/received/' + id);
}

export const getNotifications= async () => {
  return await getJson('/api/users/notifications');
}
