import { hectaresToAcres, hectaresToSqft, hectaresToSqm } from "../index";

export function formatSiteAddress(site_address) {
  const {street_number = '', street_name = '', city = ''} = site_address;
  return city ? `${street_number} ${street_name}, ${city}` : `${street_number} ${street_name}`;
}

export function formatAddress(address) {
  return formatSiteAddress(address);
}

export function formatFullAddress(site_address) {
  const {street_number = '', street_name = '', city = '', province = '', postal_code, district} = site_address;
  let address =`${street_number} ${street_name}`;
  if (district) address += `, ${district}`;
  if (city) address += `, ${city}`;
  if (province) address += `, ${province}`;
  if (postal_code) address += ` ${postal_code}`;
  return address;
}

// Round the given number to a given fractionDigits, and drop trailing zeroes.
function formatNumber(number, fractionDigits = 0) {
  const num =  Number(number).toFixed(fractionDigits);
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatSiteArea(area, unit) {
  if (unit === 'Sqft')
    return `${formatNumber(hectaresToSqft(area))} Sqft`
  else if (unit === 'Sqm')
    return `${formatNumber(hectaresToSqm(area))} Sqm`
  else
    return `${formatNumber(area, 2)} Hectares / ${formatNumber(hectaresToAcres(area), 2)} Acres`
}
