import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

export default function ReactRouterDomLink({href, children, ...props}) {
  const navigate = useNavigate();
  return (
    <Link
      {...props}
      href={href}
      onClick={e => {
        e.preventDefault();
        navigate(href)
      }}>
      {children}
    </Link>
  )
}
