import React from "react";
import { homeRoutes } from "./routes";
import { Route, Routes } from "react-router-dom";
import { Header } from "../components/common";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

const theme = createTheme();


export default function HomeRoute() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/background-1.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize:
            (window.innerWidth / window.innerHeight > 1.5) ? '100vw auto' : 'auto 100vh',
          minHeight: '100vh',
          paddingBottom: 18,
        }}>
          <CssBaseline/>
          <Header/>
          <Routes>
            {homeRoutes.map(({path, Component, ...props}, idx) =>
              <Route
                key={idx}
                path={path}
                {...props}
                element={<Component {...props} />}
              >
              </Route>
            )}
          </Routes>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
