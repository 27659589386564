import React, { useEffect, useState, useRef } from 'react';
import { Grid, IconButton, Popover } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ZoomIn, ZoomOut, Close, ChevronLeft, ChevronRight, CloudDownload } from "@mui/icons-material";
import { downloadByUrl } from '../../utils';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
    maxWidth: '90vw',
    maxHeight: '86vh',
    margin: 'auto'
  },
  paper: {
    height: '99vh',
    width: '99vw',
    padding: 16,
    textAlign: 'center',
    overflowY: 'hidden',
  }
}));

export default function ImageViewer({images, current, open, anchorEl, onClose}) {

  const classes = useStyles();
  const ref = useRef();
  const [state, setState] = useState({
    current: 0,
    imgWidth: 'auto',
    scale: 100,
    initialWidth: null,
  });

  useEffect(() => {
    setState(state => ({...state, current}));
  }, [current]);

  useEffect(() => {
    if (open)
      setState(state => ({...state, scale: 100}));
  }, [open]);


  if (images.length === 0)
    return null;

  const handleLoad = e => {
    const img = e.currentTarget;
    const parent = img.parentNode;

    const naturalWidth = img.naturalWidth;
    const naturalHeight = img.naturalHeight;
    const parentWidth = parent.offsetWidth;
    const parentHeight = parent.offsetHeight;

    let width;
    if (naturalWidth > naturalHeight) {
      width = (naturalWidth > parentWidth) ? parentWidth : naturalWidth;
    } else {
      width = (naturalHeight > parentHeight) ? (naturalWidth * parentHeight / naturalHeight) : naturalHeight;
    }

    setState(state => ({...state, imgWidth: width + 'px', initialWidth: width}));
  };


  const zoom = (deltaY) => () => {
    if (!state.initialWidth) return;

    let scale = state.scale + (deltaY < 0 ? 20 : -20);
    scale = Math.max(50, Math.min(scale, 300));
    console.log(scale)
    setState(state => ({...state, scale, imgWidth: scale * state.initialWidth / 100 + 'px'}));
  }

  const navLeft = () => {
    setState(state => ({
      ...state, current: Math.max(0, --state.current), scale: 100
    }));
  }

  const navRight = () => {
    setState(state => ({
      ...state,
      current: Math.min(images.length - 1, ++state.current), scale: 100
    }));
  }

  const download = (url, name) => () => {
    downloadByUrl(url, name);
  }


  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{left: window.innerWidth / 2, top: window.innerHeight / 2}}
      PaperProps={{
        className: classes.paper
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}>
      <Grid container>
        <Grid item sm={12}>
          <IconButton onClick={navLeft} color="default" size="large">
            <ChevronLeft/>
          </IconButton>
          <IconButton onClick={navRight} color="default" size="large">
            <ChevronRight/>
          </IconButton>
          <IconButton onClick={zoom(1)} color="primary" size="large">
            <ZoomOut/>
          </IconButton>
          <IconButton onClick={zoom(-1)} color="primary" size="large">
            <ZoomIn/>
          </IconButton>
          <IconButton
            onClick={download(images[state.current].url, images[state.current].name)}
            color="default"
            size="large">
            <CloudDownload/>
          </IconButton>
          <IconButton onClick={onClose} color="secondary" size="large">
            <Close/>
          </IconButton>
        </Grid>
        <Grid item sm={12}>
          <div className={classes.imageContainer}>
            <img
              onLoad={handleLoad}
              ref={ref}
              style={{width: state.imgWidth, overflow: 'visible', transition: 'width 0.2s'}}
              src={images[state.current].url}
              alt={images[state.current].name}/>
          </div>
        </Grid>
      </Grid>
    </Popover>
  );

}
