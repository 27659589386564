import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { OutlinedInput, FilledInput, Input as RegularInput, InputLabel, FormControl, FormHelperText } from '@mui/material';

// https://next--material-ui.netlify.app/components/text-fields/#integration-with-3rd-party-input-libraries
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const setRef = React.useCallback(
    (maskedInputRef) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      {...props}
      ref={setRef}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'_'}
      showMask
    />
  );
});

export default function PhoneInput({variant='outlined', label, onChange, value, defaultValue, error, helperText, InputLabelProps, ...props}) {
  if (!value) value = defaultValue;

  const [values, setValues] = useState({
    textmask: value || '',
  });

  useEffect(() => {
    setValues({textmask: value});
  }, [value]);

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
    onChange({target: {value: event.target.value}})
  };
  const Input = variant === 'outlined' ? OutlinedInput : (variant === 'filled' ? FilledInput : RegularInput);

  return (
    <FormControl fullWidth variant={variant} error={error} {...props}>
      <InputLabel {...InputLabelProps}>{label}</InputLabel>
      <Input
        label={label}
        value={values.textmask}
        onChange={handleChange('textmask')}
        inputComponent={TextMaskCustom}
      />
      <FormHelperText>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}
