import React, { useState } from "react";
import { Container, Link, Divider, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import { signUp } from "../../../api/auth";
import { setProperty } from '../../../utils';
import { OkDialog, SubmitButton } from "../../common";
import { useOrganizationForm } from "../../forms/organization";


const useStyles = makeStyles(theme => ({
  paper: {
    margin: '30',
    padding: 40,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 14,
      marginTop: 10,
    }
  }
}));

export const useSignUpStyle = useStyles;

export const signUpStyle = {
  item: { // used in Login
    minHeight: 80
  },
  button: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  link: {
    fontSize: '15px',
    lineHeight: 2,
    color: '#007dff',
    cursor: 'pointer',
    fontWeight: 500
  },
  title: {
    color: 'rgb(0,32,81)',
    fontWeight: 400,
    marginBottom: '30px',
  },
};

export default function SignUp() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [state, setState] = useState({
    username: '',
    password: '',
    repeat_password: '',
    alert: '',
    organization_name: '',
    primary_contact: {
      first_name: '',
      last_name: '',
      position: '',
      email: '',
      telephone: '',
      telephone_ext: '',
    },
    organization_types: []
  });
  const [showDialog, setShowDialog] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleChange = name => e => {
    const value = e.target.value;
    setState(prev => setProperty(prev, name, value, false));
  };

  const [organizationForm, state2, checkAll, setErrors] = useOrganizationForm({
    formData: state, onChange: handleChange
  });

  const submit = async () => {
    if (checkAll()) {
      setWaiting(true);
      const res = await signUp(state2);
      if (res.success) {
        setErrors({});
        setShowDialog(true);
      } else {
        setWaiting(false);
        setErrors(res.detail);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper} variant="elevation" elevation={5}>
        <Typography variant="h4" sx={signUpStyle.title}>
          Registration for New Organization
        </Typography>
        {organizationForm}
        <Typography variant="caption">
          <span style={{color: 'red'}}>{state.alert}</span>
        </Typography>
        <SubmitButton sx={signUpStyle.button} onClick={submit} loading={waiting}>
          Submit
        </SubmitButton>
        <Divider/>
        <Link variant="body2" sx={signUpStyle.link} onClick={() => navigate('/login')}>
          Back to Login?
        </Link>
      </Paper>
      <OkDialog
        open={showDialog}
        handleClose={() => {
          setShowDialog(false);
          navigate('/login');
        }}
        message={'Please check your email and follow the instructions to finish the registration process.' +
        ' Please also check the junk folder and report that the email is not junk mail!'}
        title={'Email sent'}
      />
    </Container>
  )
}
