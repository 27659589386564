import React, { useContext, useEffect, useState } from "react";
import { Fade } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Loading, Link } from "../../../../common";
import { EnhancedTable } from '../../../../common/table'
import { formatSiteArea } from "../../../../../utils/data/formatter";
import { getLandUseTypes, getProjectTypes } from "../../../../../api/project";
import { OntologyContext } from "../../../../../context";
import { getFavouriteOpportunities, removeOpportunityFromFavourites } from "../../../../../api/partnership";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid'
  },
}));

const getAddress = ({street_number = '', street_name = ''}) =>
  (street_number === '' && street_name === '') ? 'Not Provided' : `${street_number} ${street_name}`;

const getProjectType = (projectType, projectTypesMap) =>
  projectType.map(type => projectTypesMap[type]).join(', ');

const getLandUse = (landUse, landUseTypesMap) =>
  landUse.map(type => landUseTypesMap[type]).join(', ');


const columns = [
  {
    label: 'Site Address',
    sortBy: ({site_address}) => site_address && getAddress(site_address),
    body: ({site_address, _id}) => {
      return <Link href={'/console/opportunity/details/' + _id}>
        {getAddress(site_address)}
      </Link>;
    },
  },
  {
    label: 'Project Type',
    body: ({project_types = []}, {projectTypes}) => {
      return getProjectType(project_types, projectTypes);
    }
  },
  {
    label: 'Designated Land Use',
    body: ({designated_land_uses = []}, {landUseTypes}) => {
      return getLandUse(designated_land_uses, landUseTypes);
    }
  },
  {
    label: 'Site Size',
    sortBy: ({site_area}) => site_area,
    body: ({site_area, site_area_unit}) => {
      return formatSiteArea(site_area, site_area_unit);
    }
  },
];

export default function FavouriteOpportunities() {
  const classes = useStyles();
  const ontologyContext = useContext(OntologyContext);
  const [categories, setCategories] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    let active = true;

    (async function () {
      const newCategories = {};
      newCategories.projectTypes = await ontologyContext.request(getProjectTypes);
      newCategories.landUseTypes = await ontologyContext.request(getLandUseTypes);

      const newOpportunities = await getFavouriteOpportunities();

      if (active) {
        setCategories(newCategories);
        setOpportunities(newOpportunities);
        setLoaded(true);
      }

    })();

    return () => {
      active = false;
    }
  }, [ontologyContext]);

  const handleDelete = async (ids) => {
    for (const id of ids) {
      removeOpportunityFromFavourites(id);
    }
  }

  if (loaded)
    return (
      <Fade in>
        <div>
          <EnhancedTable
            className={classes.root}
            title={"My Favourite Opportunities"}
            data={opportunities}
            columns={columns}
            extraData={categories}
            onDelete={handleDelete}
          />
        </div>
      </Fade>
    )
  else
    return <Loading message="Loading opportunities"/>
}
