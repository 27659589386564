const apiKey = 'W8iGW975XeYCxpjY3Ki_CkVQaxt9yI-Hi_nlgoQ26eg';

/**
 * Get Latitude and Longitude
 * @param fullAddress
 * @return {Promise<{lat: number, lng: number}>}
 */
export const getGeoLocation = async (fullAddress) => {
  const data = await (await fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${fullAddress}&apiKey=${apiKey}`)).json();
  if (data.items.length > 5)
    console.warn('There are more the 5 results for address ' + fullAddress);
  if (data.items.length === 0) {
    console.error('Invalid address: ' + fullAddress);
    return {lat: 0, lng: 0}
  }

  return data.items[0].position;
}

export const getAutoSuggestions = async address => {
  const data = await (await fetch(`https://geocode.search.hereapi.com/v1/autosuggest?apiKey=${apiKey}&at=0,0&in=countryCode:CAN&q=${address}`)).json();

  // Remove suggestions without address field
  data.items = data.items.filter(item => item.address != null);
  return data;
}

export const LookUpByHereId = async id => {
  return await (await fetch(`https://lookup.search.hereapi.com/v1/lookup?apiKey=${apiKey}&id=${id}`)).json();
}
