import React from "react";
import {
  FileOutline, FileImageOutline, FilePdfBox, FileTableOutline, FileDocumentOutline,
  FileMusicOutline, FileVideoOutline, FolderZipOutline
} from "mdi-material-ui";
import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  icon: {
    color: '#707070',
    height: 80,
    width: 80,
  },
  greenIcon: {
    color: '#22a463',
    height: 80,
    width: 80,
  },
  redIcon: {
    color: '#b32626',
    height: 80,
    width: 80,
  },
  blueIcon: {
    color: '#267bb3',
    height: 80,
    width: 80,
  },
  extension: {
    color: '#626262',
    position: "relative",
    top: -50,
    height: 0,
    fontWeight: 400
  }
}));

const videoFormats = ["3g2", "3gp", "3gpp", "4xm", "amv", "anm", "apng", "asf", "avi", "avs",
  "bethsoftvid", "bfi", "bik", "cavs", "cdxl", "divx", "dsicin", "", "dts", "dtshd", "dv", "ea",
  "ea_cdata", "f4v", "ffm", "ffm", "filmstrip", "film_cpk", "flc", "flic", "flv", "gxf", "idcin",
  "ingenient", "ipmovie", "iso", "iv8", "ivf", "jv", "libnut", "lmlm4", "lvf", "lxf", "m1v", "m2t",
  "m2ts", "m2v", "m4v", "mgsts", "mjpeg", "mjpg", "mkv", "mm", "mod", "mov", "mp4", "mpe", "mpeg",
  "mpeg1", "mpegts", "mpegtsraw", "mpg", "mpv", "msnwctcp", "mts", "mv", "mvi", "mxg", "nc", "nut",
  "nuv", "ogv", "paf", "pmp", "ppt*", "pptx*", "psxstr", "pva", "r3d", "rawvideo", "rdt", "rl2",
  "rmvb", "roq", "rpl", "sdp", "sdr2", "smk", "smk", "smush", "swf", "thp", "tiertexseq", "tod",
  "trp", "txd", "vc1", "vc1test", "viv", "vmd", "vob", "vro", "wc3movie", "webm", "wmv", "wsvqa",
  "wtv", "xvid", "yo"];


export function FileIcon(props) {
  const {filename} = props;
  const classes = useStyles();

  const hasExtension = filename.indexOf('.') !== -1;
  const extension = hasExtension && filename.slice(filename.lastIndexOf('.') + 1).toLowerCase();

  let component = (
    <div>
      <FileOutline className={classes.icon}/>
      {(extension.length <= 4) && <Typography className={classes.extension}>{extension}</Typography>}
    </div>
  );

  if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'tiff', 'psd', 'raw', 'bmp', 'heif', 'indd', 'svg'].includes(extension)) {
    component = <FileImageOutline className={classes.greenIcon}/>;
  } else if (extension === 'pdf') {
    component = <FilePdfBox className={classes.redIcon}/>;
  } else if (['xls', 'xlsx'].includes(extension)) {
    component = <FileTableOutline className={classes.greenIcon}/>;
  } else if (['doc', 'docx'].includes(extension)) {
    component = <FileDocumentOutline className={classes.blueIcon}/>;
  } else if (['rar', 'zip', '7z', 'gz'].includes(extension)) {
    component = <FolderZipOutline className={classes.icon}/>;
  } else if (['mp3', 'aac', '3gp', 'ape', 'flac', 'webm', 'ogg'].includes(extension)) {
    component = <FileMusicOutline className={classes.greenIcon}/>;
  } else if (videoFormats.includes(extension)) {
    component = <FileVideoOutline className={classes.redIcon}/>;
  }

  return component;
}
