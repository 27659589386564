import { postJson, getJson, putJson, deleteJson } from "../index";

export const addListing = async (data) => {
  return await postJson('/api/opportunities', data);
};

export const updateListing = async (id, data) => {
  return await putJson('/api/opportunities/' + id, data);
};

export const deleteListing = async (id) => {
  return await deleteJson('/api/opportunities/' + id);
};

export const getListing = async (id) => {
  return await getJson('/api/opportunities/' + id);
};

export const getAllListingsForCurrentUser = async () => {
  return await getJson('/api/opportunities/user');
};

export const searchListings = async (data) => {
  return await postJson('/api/opportunities/search', data);
};

export const addOpportunityToFavourites = async (id) => {
  return await postJson(`/api/opportunities/favourite/${id}/add`);
};

export const removeOpportunityFromFavourites = async (id) => {
  return await postJson(`/api/opportunities/favourite/${id}/remove`);
};

export const getFavouriteOpportunities = async () => {
  return await getJson('/api/opportunities/favourites');
};

export const getFavouriteHistory = async () => {
  return await getJson('/api/opportunities/favourites/history');
};

export const isFavouriteOpportunity = async (id) => {
  return await postJson(`/api/opportunities/favourite/${id}/check`);
};
