import React, { useContext, useEffect } from "react";
import { consoleRoutes } from "./routes";
import { Route, Routes, useNavigate } from "react-router-dom";
import Console from "../components/pages/Console/Console";
import { UserContext } from "../context";
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Page404 from '../components/pages/404';
import { getNotifications } from "../api/notification";


const theme = createTheme({
  palette: {
    primary: {main: "#0d5eab"},
    secondary: pink
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {main: "#005ba0"},
    secondary: {main: "#f48fb1"}
  },
});

export default function ConsoleRoute() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    let active = true, timerId;

    const fn = () => getNotifications().then(({notifications}) => {
      if (active)
        for (const {type, id, message} of notifications) {
          userContext.enqueueMessage({
            type, message, buttonText: 'View', id,
            buttonOnClick: () => navigate('/console/notification/received')
          });
        }

    });
    fn();
    timerId = setInterval(fn, 10 * 1000);

    return () => {
      active = false;
      clearInterval(timerId);
    }
  }, [navigate, userContext]);

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // console.log(prefersDarkMode)

  if (userContext.roles.includes('organization'))
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={userContext.theme === 'light' ? theme : darkTheme}>
          <Console>
            <Routes>
              {consoleRoutes.map(({path, Component, ...props}, idx) =>
                <Route
                  key={idx}
                  path={path}
                  {...props}
                  element={<Component {...props}/>}
                >
                </Route>
              )}
            </Routes>
          </Console>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  else
    return <Page404/>
}
