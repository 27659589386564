import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Link,
  ImageListItem,
  ImageList,
  Divider,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { getListing } from "../../../../api/partnership";
import { Loading, ImageViewer } from "../../../common";
import { formatFullAddress, formatSiteAddress, formatSiteArea } from "../../../../utils/data/formatter";
import { getSpaceTypes } from "../../../../api/project";
import { getPartnershipRoles } from "../../../../api/partnership/partnershipRole";
import { downloadFile } from "../../../../api/upload";
import { OntologyContext } from "../../../../context";
import { server } from "../../../../config";
import OrganizationPopup from "./OrganizationPopup";
import {cache} from "../../../../utils/cache/memoryCache";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    // color: theme.palette.mode === 'light' && 'rgb(0,68,171)',
    fontWeight: 400,
    marginBottom: 10,
  },
  subTitle: {
    // color: theme.palette.mode === 'light' && 'rgb(0,68,171)',
    marginBottom: 20,
    whiteSpace: 'pre-line',
    maxHeight: 300,
    overflow: "auto",
    overflowWrap: 'break-word'
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  dataContainer: {
    display: 'flex'
  },
  dataTitle: {
    fontWeight: 500,
    marginRight: 8,
  },
  img: {
    cursor: "pointer",
  },
  contactButton: {
    backgroundColor: '#3b924a',
    '&:hover': {
      backgroundColor: '#14a914',
    },
  }
}));

export function getDataDisplay({opportunity, projectTypes, landUseTypes, spaceTypes, partnershipRoles}) {
  const {
    designated_land_uses = [], project_types = [],
    site_area, site_area_unit, spaces_offered = [], partnership_roles_required = [], files = [], organization = {},
    modified_at, other_site_addresses = [], missions, core_values // current_land_uses = [], proposed_land_uses = [],
  } = opportunity;

  return {
    Organization: {
      hide: !organization.name,
      render: () => <OrganizationPopup name={organization.name}
                                       types={organization.organization_types}>
        <Typography variant="body1" gutterBottom>
          {organization.name}
        </Typography>
      </OrganizationPopup>
    },
    'Other Site Addresses': {
      hide: other_site_addresses.length === 0,
      render: () => <Typography style={{whiteSpace: 'pre'}}>
        {other_site_addresses.map(address => formatFullAddress(address)).join('\n')}
      </Typography>
    },
    'Partnership Roles required': {
      hide: partnership_roles_required.length === 0,
      render: partnership_roles_required.map(type => partnershipRoles[type]).join(', ')
    },
    'Site size': {
      hide: !site_area,
      render: formatSiteArea(site_area, site_area_unit)
    },
    'Project type': {
      hide: project_types.length === 0,
      render: project_types.map(type => projectTypes[type]).join(', ')
    },
    // 'Current land use': {
    //   hide: current_land_uses.length === 0,
    //   render: current_land_uses.map(type => landUseTypes[type]).join(', ')
    // },
    // 'Proposed land use': {
    //   hide: proposed_land_uses.length === 0,
    //   render: proposed_land_uses.map(type => landUseTypes[type]).join(', ')
    // },
    'Designated land use': {
      hide: designated_land_uses.length === 0,
      render: designated_land_uses.map(type => landUseTypes[type]).join(', ')
    },
    'Space Offered': {
      hide: spaces_offered.length === 0,
      render: spaces_offered.map(type => spaceTypes[type]).join(', '),
    },
    'Missions': {
      hide: !missions,
      render: missions,
    },
    'Core values': {
      hide: !core_values,
      render: core_values,
    },
    'Resources': {
      hide: files.length === 0,
      // parentStyle: {display: 'initial'},
      render: (
        <Typography variant="h6">
          {files.map((item, idx) =>
            <Link href="#" onClick={() => downloadFile(item.id, item.filename)} key={idx}>
              <Typography>{item.filename}</Typography>
            </Link>
          )}
        </Typography>
      )
    },
    'Updated at': {
      hide: modified_at == null,
      render: () => new Date(modified_at).toLocaleString()
    }
  }
}

export default function OpportunityDialog({id, projectTypes, landUseTypes, open, onClose, onContact}) {
  const classes = useStyles();
  const ontologyContext = useContext(OntologyContext);

  const [imageViewer, setImageViewer] = useState({
    open: false,
    images: [],
    current: 0,
  });

  const [opportunity, setOpportunity] = useState({});
  const [spaceTypes, setSpaceTypes] = useState({});
  const [partnershipRoles, setPartnershipRoles] = useState({});

  useEffect(() => {
    // load when id is given
    setOpportunity({});
    if (id) {
      getListing(id).then(data => {
        setOpportunity(data);
        setImageViewer(state => ({
          ...state,
          images: (data.images || []).map(image => ({
            url: cache.has(image.id) ? cache.get(image.id) : `${server.address}/api/upload/${image.id}`,
            name: image.filename
          }))
        }))
      });
    }
    return () => setImageViewer({
      open: false,
      images: [],
      current: 0,
    });
  }, [id]);

  useEffect(() => {
    ontologyContext.request(getSpaceTypes).then(data => setSpaceTypes(data));
    ontologyContext.request(getPartnershipRoles).then(data => setPartnershipRoles(data));
  }, [ontologyContext])

  if (Object.keys(opportunity).length === 0 && open)
    return (
      <Dialog open={open} onClose={onClose} maxWidth={"md"}>
        <DialogContent>
          <Loading message="Loading Details.."/>
        </DialogContent>
      </Dialog>
    )

  const {
    site_address = {}, brief_description, images = [],
  } = opportunity;

  const dataDisplay = getDataDisplay({opportunity, projectTypes, landUseTypes, spaceTypes, partnershipRoles});

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogContent>
        <Typography variant="h4" className={classes.title}>
          {formatSiteAddress(site_address)}
        </Typography>
        <Typography variant="body1" className={classes.subTitle}>
          {brief_description || 'No descriptions.'}
        </Typography>

        {images.length > 0 &&
        <ImageList variant={"quilted"} cols={3} spacing={12} style={{
          gridTemplateColumns: 'repeat(6, calc(50% - 40px))',
          gridTemplateRows: 'minmax(300px, 30vh)',
        }}>
          {images.map(({filename, id}, idx) => {
            return (
              <ImageListItem key={idx} style={{gridRow: 1}}>
                <img className={classes.img} src={cache.has(id) ? cache.get(id) : `${server.address}/api/upload/${id}`} alt={filename} onClick={() => {
                  setImageViewer(state => ({
                    ...state,
                    open: true,
                    current: idx,
                  }));
                }}/>
              </ImageListItem>
            )
          })}
        </ImageList>}

        <Divider className={classes.divider}/>

        {Object.entries(dataDisplay).map(([title, {hide, render, parentStyle}]) => {
          if (hide) return null;
          if (typeof render === "function")
            render = render();
          return (
            <div key={title} className={parentStyle ? parentStyle : classes.dataContainer}>
              <Typography variant="body1" className={classes.dataTitle}>{title}: </Typography>
              {typeof render === "string" ? <Typography variant="body1">{render}</Typography> : render}
            </div>
          );
        })}
      </DialogContent>

      <DialogActions>

        <Button variant="contained" color="primary" className={classes.contactButton} onClick={onContact(id)}>
          Contact
        </Button>

        <Button variant="contained" color="primary" className={classes.button} onClick={onClose}>
          Close
        </Button>

      </DialogActions>


      <ImageViewer
        {...imageViewer}
        onClose={() => setImageViewer(state => ({...state, open: false}))}
      />

    </Dialog>
  )
}
