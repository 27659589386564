import { Checkbox, FormControlLabel, } from "@mui/material";
import React from "react";

export function CheckboxWithLabel({label, fullWidth, error, helperText, value, onChange, defaultValue, ...props}) {
  if (!value) value = defaultValue;
  return <FormControlLabel
    control={
      <Checkbox
        checked={value}
        color="primary"
        onChange={e => onChange({target: {value: e.target.checked}})}
        {...props}
      />
    }
    label={label}
  />
}
