import React, { useContext, useEffect, useState } from "react";
import { Typography, Popover, Paper, Link, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { OntologyContext } from "../../../../context";
import { getOrganizationTypes } from "../../../../api/organization/organizationType";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    paddingBottom: 50,
  },
  clickableText: {
    '& .MuiTypography-root': {
      cursor: 'pointer',
      textDecoration: 'underline solid',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  },
  button: {
    margin: 6,
    position: 'absolute',
    right: 6,
  }
}));

export default function OrganizationPopup({name, types = [], children}) {
  const ontologyContext = useContext(OntologyContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orgTypes, setOrgTypes] = useState({});

  useEffect(() => {
    ontologyContext.request(getOrganizationTypes).then(data => setOrgTypes(data));
  }, [ontologyContext]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Link onClick={handleClick} className={classes.clickableText}>
        {children}
      </Link>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handlePopoverClose}
      >

        <Paper className={classes.root}>
          <Typography variant="h6">
            {name}
          </Typography>
          {orgTypes && <Typography variant="body1">
            {types.map(item => orgTypes[item]).join(', ')}
          </Typography>}

          <Button className={classes.button} onClick={handlePopoverClose}>
            Close
          </Button>
        </Paper>
      </Popover>
    </React.Fragment>
  );
}
