import React from "react";
import { Grid } from "@mui/material";
import { FileCard } from "./FileCard";

export function FileList(props) {
  const {files, onClick} = props;

  return (
    <Grid container spacing={2}>
      {files.map((item, idx) => (
        <Grid item key={idx}>
          <FileCard filename={item.filename} onClick={onClick}/>
        </Grid>
      ))}
    </Grid>
  )
}
