import React, { useEffect, useState } from "react";
import { Container, Paper, Typography, Divider, CircularProgress, Fade } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { getServerStatus } from '../../../../api/admin';
import { Loading, CircularProgressWithLabel } from "../../../common";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 40,
    [theme.breakpoints.down('md')]: {
      padding: 14,
    }
  },
  title: {
    color: theme.palette.mode === 'light' && 'rgb(0,68,171)',
    paddingBottom: 20,
  },
  divider: {
    marginBottom: 10,
  },
}));

export default function ServerStatus() {
  const classes = useStyles();
  const [status, setStatus] = useState({
    status: 'N/A',
    buildTime: 'N/A',
    docsUpdateTime: 'N/A',
    frontendUpdateTime: 'N/A',
    loadAverage: '',
    mem: {free: '0', total: '0'},
  });

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    let active = true;
    const updateStatus = () => {
      setRefreshing(true);
      getServerStatus()
        .then((data) => {
          if (active) {
            setStatus(status => ({
              ...status, ...data,
            }));
            setRefreshing(false);
            setLoading(false);
          }
        });
    };
    updateStatus();
    const task = setInterval(updateStatus, 5000);
    return () => {
      active = false;
      clearInterval(task);
    }
  }, []);

  if (loading)
    return <Loading message="Loading..."/>;

  const usedMemory = Math.round((status.mem.total - status.mem.free) / 1024 / 1024 / 1024 * 100) / 100;
  const totalMemory = Math.round(status.mem.total / 1024 / 1024 / 1024 * 100) / 100;

  return (
    <Fade in>
      <Container maxWidth="lg">
        <Paper className={classes.paper} elevation={5}>
          <Typography variant="h4" className={classes.title}>
            Server Status {refreshing && <CircularProgress size={28} color="secondary"/>}
          </Typography>
          <Divider className={classes.divider}/>

          <Typography variant="h6">
            CPU Model: {status.cpuModel}
          </Typography>

          <Typography variant="h6">
            Load Average: {status.loadAverage}
          </Typography>

          <Typography variant="h6" style={{display: 'flex'}}>
            Memory Usage: <CircularProgressWithLabel value={usedMemory / totalMemory * 100}
                                                     style={{margin: '0 10px 0 10px'}}/>
            {usedMemory} GB / {totalMemory} GB
          </Typography>

          <Typography variant="h6">
            Current Job:
            {status.status.trim() !== 'Done' && status.status !== 'N/A' && <CircularProgress size={24}/>}
            {' ' + status.status}
          </Typography>

          <Typography variant="h6">
            Last Update: {status.buildTime}
          </Typography>

          <Typography variant="h6">
            Frontend Updated at: {status.frontendUpdateTime}
          </Typography>

          <Typography variant="h6">
            Docs Updated at: {status.docsUpdateTime}
          </Typography>

          <Divider className={classes.divider}/>

          <Typography variant="caption">
            Refreshing every 5 seconds.
          </Typography>
        </Paper>
      </Container>
    </Fade>
  )
}
