export * from './constants';

export function getProperty(variable, propertyPath) {
  try {
    const path = propertyPath.split('.');
    let curr = variable;
    for (const p of path) {
      curr = curr[p];
    }
    return curr;
  } catch (e) {
    throw Error(`Failed to get property path ${JSON.stringify(propertyPath)} from ${JSON.stringify(variable)}`)
  }
}

export function setProperty(variable, propertyPath, valueToSet, shallowCopy = true) {
  try {
    const path = propertyPath.split('.');
    let curr = variable;
    for (let i = 0; i < path.length - 1; i++) {
      if (curr[path[i]] === undefined)
        curr = curr[path[i]] = {}
      else
        curr = curr[path[i]];
    }
    curr[path[path.length - 1]] = valueToSet;
    if (shallowCopy)
      return {...variable};
    else
      return variable;
  } catch (e) {
    throw Error(`Failed to set property path ${propertyPath} from ${JSON.stringify(variable)}`)
  }
}

/**
 * Performs a deep merge of `source` into `target`.
 * @param target
 * @param source
 * @return {*}
 */
export function deepAssign(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepAssign(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

/**
 * Using object 1's keys to shallow compare on object 2.
 * @param obj1
 * @param obj2
 * @return {boolean} true if obj2 contains all identity properties in obj1
 */
export function shallowCompare(obj1, obj2) {
  for (const key of Object.keys(obj1)) {
    if (!(key in obj2) || obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export function arrayToObject(arr) {
  const obj = {};
  for (const name of arr)
    obj[name] = name;
  return obj;
}

export function hectaresToAcres(number) {
  return number * 2.47105;
}

export function acresToHectares(number) {
  return number * 0.404686;
}

export function sqftToHectares(number) {
  return number / 107639;
}

export function hectaresToSqft(number) {
  return number * 107639;
}

export function sqmToHectares(number) {
  return number / 10000;
}

export function hectaresToSqm(number) {
  return number * 10000;
}

export async function downloadByUrl(href, fileName) {
  fileName = fileName || href.slice(href.lastIndexOf('/'));
  const blob = await ( await fetch(href)).blob();
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // If IE, you must uses a different method.
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}

const debounceMap = new Map();
export const debounce = (uniqueName, fn, timeInMs) => {
  if (debounceMap.has(uniqueName))
    clearTimeout(debounceMap.get(uniqueName));
  debounceMap.set(uniqueName, setTimeout(fn, timeInMs));
}
