import React from 'react';
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { getAutoSuggestions } from "../../api/here";
import { debounce } from '../../utils';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function AddressAutoComplete({label, onSelect, ...props}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    const updateFunc = () => getAutoSuggestions(inputValue).then(({items}) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (items) {
          newOptions = [...newOptions, ...items];
        }

        setOptions(newOptions);
      }
    });
    debounce('AddressAutoComplete', updateFunc, 200);

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Autocomplete
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (onSelect) onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          {...props}
        />
      )}
      getOptionLabel={(option) => {
        return option.title;
      }}
      renderOption={(props, option) => {
        // Show highlights
        const {title, highlights} = option;
        const parts = [];
        let prev_end = 0;
        for (const {start, end} of highlights.title) {
          if (start > prev_end) {
            parts.push({
              text: title.slice(prev_end, start),
            })
          }
          parts.push({
            text: title.slice(start, end),
            highlight: true
          });
          prev_end = end;
        }
        if (prev_end <= title.length)
          parts.push({text: title.slice(prev_end)});

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon}/>
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.address && option.address.label}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
