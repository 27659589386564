import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, Tooltip, Box, Typography } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {HelpOutline as DescriptionIcon} from "@mui/icons-material";

const filter = createFilterOptions();

export default function MultiSelectField({options, label, value, defaultValue, onChange, helperText, multiple = true, allowAdd = false, clearable = true, error, TextFieldProps, ...props}) {
  // options is {value: label, ...} or [value1, value2]

  if (value == null) value = defaultValue;

  const [state, setState] = useState({
    options: [], // [[key: label], [key, label]]
    value: multiple ? [] : null,
    initialized: false,
  });

  useEffect(() => {
    // ["option1", "option2", ...]
    if (Array.isArray(options)) {
      setState(state => ({...state, options: options.map(option => [option, option])}));
    }
    // {option1: option1Label, ...}
    else if (Object.values(options).length > 0 && typeof Object.values(options)[0] !== "object") {
      setState(state => ({...state, options: Object.entries(options)}));
    }
    // {options1: {label: "option1label", comment: "option1Description"}
    else {
      setState(state => ({
        ...state,
        options: Object.entries(options).map(([key, val]) => [key, val.label, val.comment]),
      }));
    }
  }, [options]);

  useEffect(() => {
    if (multiple) {
      setState(state => ({
        ...state,
        value: value ? state.options.filter(item => value.includes(item[0])) : []
      }));
    } else {
      setState(state => {
        const existingOption = state.options.find(item => item[0] === value);
        if (existingOption)
          return {...state, value: existingOption}
        else if (value == null || value === '') {
          return {...state, options: [...state.options], value: null}
        } else {
          const newOption = [value, value];
          return {...state, options: [...state.options, newOption], value: newOption}
        }
      });
    }
  }, [multiple, value, allowAdd]);


  return (
    <Autocomplete
      loading={Object.entries(options).length === 0}
      disableClearable={!clearable}
      multiple={multiple}
      disableCloseOnSelect={multiple}

      // Creatable
      freeSolo={allowAdd}
      selectOnFocus={allowAdd ? true : undefined}
      clearOnBlur={allowAdd ? true : undefined}
      handleHomeEndKeys={allowAdd ? true : undefined}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (allowAdd && params.inputValue !== '') {
          filtered.push([params.inputValue, params.inputValue, null, 'add-new']);
        }
        return filtered;
      }}
      forcePopupIcon


      renderOption={(props, option) => (
        <li {...props}>
          {option[3] === 'add-new' ? `Click to Add "${option[1]}"` : option[1]}
          {option[2] && <Tooltip title={<Typography variant={"body2"}>{option[2]}</Typography>} arrow>
            <Box sx={{paddingLeft: 1, fontSize: '1.1rem'}}>
              <DescriptionIcon fontSize={"inherit"} onClick={e => e.stopPropagation()}/>
            </Box>
          </Tooltip>}
        </li>
      )}
      options={state.options}
      // getOptionSelected={(option, value) => {
      //   return option[0] === value;
      // }}
      getOptionLabel={(option) => {
        return option[1];
      }}
      onChange={(e, newValue) => {
        let value;
        if (multiple) {
          value = newValue.map(val => val[0]);
        } else {
          value = newValue && newValue[0];
        }
        setState(state => ({...state, value: newValue}));
        onChange({target: {value}});
      }}

      value={state.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          variant="outlined"
          label={label}
          // placeholder="..."
          autoComplete={multiple ? undefined : "off"}
          helperText={helperText}
        />
      )}
      {...props}
    />
  );
}
