/**
 * Use Google Maps in React without custom libraries.
 * Some of the ideas are from
 * https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/
 */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Paper, Tooltip} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createInfoWindow, loadGeoJson } from "./helpers";

/**
 * @type {google.maps}
 */
const Maps = window.google?.maps || {};
const {Map, Marker} = Maps;

let GoogleMap;

// Export empty div if no network to load google maps.
if (Map) {
  // Google map in each pages, native Google API implementation
  // (without any react wrapper, library)
  GoogleMap = function ({mapRef, noInnerStyle, events, ...props}) {
    const navigate = useNavigate();
    const defaultCenter = useMemo(() => ({lat: 43.6870, lng: -79.4132}), []);
    const [googleMarkers] = useState([]);
    const [infoWindows] = useState([]);
    const {
      center = defaultCenter,
      zoom = 13,
      markers = [],
      height = '100%',
      children
    } = props;
    const [map, setMap] = useState(null);
    const [tooltip, setTooltip] = useState({open: false, text: ''});

    // InitialBounds should only be applied when the map component is mounted first time
    const isInitialBoundsUsed = useRef(false);

    // Expose the Google Map ref
    if (mapRef)
      mapRef.current = map;
    window.map = map;

    useEffect(() => {
      setMap(new Map(document.getElementById('map'), {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControlOptions: {
          style: Maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: Maps.ControlPosition.LEFT_BOTTOM
        },
        maxZoom: 18,
      }));
    }, []);

    useEffect(() => {
      if (map) {
        map.panTo(center);
        map.setZoom(zoom);
        loadGeoJson(map);

        map.data.addListener("mouseover", (event) => {
          map.data.revertStyle();
          map.data.overrideStyle(event.feature, {strokeWeight: 5, fillOpacity: 0.05});

          const name = event.feature.getProperty('name');
          console.log('mouse over', name)
          setTooltip({open: true, text: name});
        });
        map.data.addListener("mouseout", (event) => {
          map.data.revertStyle();
          console.log('mouse leave')
          setTooltip(state => ({open: false, text: state.text}));
        });
      }
    }, [map, center, zoom])

    useEffect(() => {
      // remove current markers first
      for (const marker of googleMarkers) {
        marker.setMap(null);
      }
      // Clear data
      googleMarkers.splice(0, googleMarkers.length);
      infoWindows.splice(0, infoWindows.length);

      // Info Window listener
      const handleInfoWindowMouseEnter = e => {
        setTooltip(state => ({...state, open: false}));
      }

      const bounds = new Maps.LatLngBounds();

      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i];
        if (marker.position.lat && marker.position.lng) {
          bounds.extend(marker.position);
        }
        const currMarker = new Marker({
          position: marker.position,
          map: map,
          // title: marker.title,
        });
        googleMarkers.push(currMarker);
        // Info window is opened when mouse over the marker.
        // It also stays open after the marker is clicked.
        const infoWindow = createInfoWindow(map, currMarker, marker, navigate, i, handleInfoWindowMouseEnter);
        infoWindows.push(infoWindow);
      }

      // Center the map
      if (map && center !== defaultCenter && !isInitialBoundsUsed.current) {
        // Restore to previous center and zoom
        map.setOptions({center, zoom});
        isInitialBoundsUsed.current = true;
      } else if (map && bounds.getCenter().lat()) {
        map.fitBounds(bounds, 80);
        map.panTo(bounds.getCenter());
      }

      // customize marker and infoWindow events
      for (const marker of googleMarkers) {
        marker.addListener('mouseover', e => {
          setTooltip(state => ({...state, open: false}));
        });
        marker.addListener('mouseout', e => {
          setTooltip(state => ({...state, open: true}));
        });
      }

    }, [infoWindows, center, defaultCenter, zoom, isInitialBoundsUsed, googleMarkers, markers, map, navigate]);

    useEffect(() => {
      let active = true;
      // Do nothing when the map component is unmounted. (active = false)
      const genFn = fn => (...args) => active && fn(...args);
      if (map && events) {
        for (const {name, fn} of events) {
          map.addListener(name, genFn(fn));
        }
      }
      return () => {
        active = false;
      };
    }, [map, events]);

    return useMemo(() =>
        <Paper elevation={5} style={{position: 'relative', height: '100%', width: '100%'}}>
          <Tooltip open={tooltip.open} title={tooltip.text} placement="top" followCursor describeChild={false}>
            <div style={{width: '100%', height}} id="map"/>
          </Tooltip>
          {noInnerStyle ? children :
            <div style={{position: 'absolute', zIndex: 150, left: 0, top: 0}}>
              {children}
            </div>}
        </Paper>
      , [noInnerStyle, height, children, tooltip]);
  }
} else {
  GoogleMap = function () {
    console.info('Google maps javascript is not loaded. Are you offline?');
    return <Paper elevation={5} style={{width: '100%', height: '40vh', marginTop: 100, textAlign: 'center'}}>
      Google Map is not correctly loaded.
    </Paper>
  }
}

export default GoogleMap;
