import React, { useCallback } from "react";
import { Card, CardActionArea, CardContent, Grid, Tooltip, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {FileIcon} from './FileTypeIcons';

const useStyles = makeStyles(theme => ({
  card: {
    width: 180,
    // height: 180,
  }
}));

export function FileCard(props) {
  const {filename, id, onClick} = props;
  const classes = useStyles();

  const handleClick = useCallback(() => {
    onClick(id, filename);
  }, [onClick, id, filename]);

  return (
    <Tooltip title={filename}>
      <Card className={classes.card}>
        <CardActionArea onClick={handleClick}>
          <CardContent>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <FileIcon filename={filename}/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" noWrap style={{color: 'rgba(0,0,0,0.87)'}}>
                  {filename}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );

}
