import React, { useEffect, useMemo, useState } from 'react';
import { UserContext, getUserContext, OntologyContext } from './context';
import { HomeRoute, ConsoleRoute } from './routes';
import ErrorBoundary from './components/common/ErrorBoundary';
import { getFrontendUpdateTime } from "./api/auth";
import { Button, Snackbar, Alert, Slide, Box } from "@mui/material";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/console/*" element={<ConsoleRoute/>}/>
      <Route path="/*" element={<HomeRoute/>}/>
    </>
  )
);

function App() {
  const [messageQueue, setMessageQueue] = useState([]);
  const [userContext, setUserContext] = useState({
    ...getUserContext(),
    /**
     * Update global user context.
     * Important: This function should not be invoked during rendering, it will cause infinite recursive rendering.
     *    Invokes within a callback/anonymous function instead.
     * @param user {{username, firstName, lastName, token,}}
     */
    updateUser: (user) => {
      localStorage.setItem('userContext', JSON.stringify(user));
      setUserContext(state => ({...state, ...user}));
    },

    updateTheme: theme => {
      setUserContext(state => {
        localStorage.setItem('theme', theme);
        return ({...state, theme});
      });
    },

    updateVersion: (version, reload = true) => {
      setUserContext(state => ({...state, version}));
      localStorage.setItem('version', version);
      if (reload)
        window.location.reload(true);
    },

    enqueueMessage: (data) => {
      setMessageQueue(queue => {
        if (data.id) {
          const existedIdx = queue.findIndex(item => item.id === data.id);

          if (existedIdx !== -1) {
            if (queue[existedIdx].message === data.message) return queue;
            else queue.splice(existedIdx, 1);
          }
        }
        return [...queue, {id: Date.now(), ...data, visible: true}];
      })
    },
  });

  const [newVersion, setNewVersion] = useState(null);

  useEffect(() => {
    const checkNewVersion = () => {
      getFrontendUpdateTime()
        .then(({frontendUpdateTime}) => {
          // First time with no version stored
          if (!userContext.version) {
            userContext.updateVersion(frontendUpdateTime, false);
          } else if (userContext.version !== frontendUpdateTime) {
            setNewVersion(frontendUpdateTime);
          }
        })

    };
    if (process.env.NODE_ENV === 'production') {
      checkNewVersion();
      // check every 15 seconds
      const task = setInterval(checkNewVersion, 15 * 1000);
      return () => clearInterval(task);
    }

  }, [userContext]);

  const ontology = useMemo(() => {
    const data = new Map();
    data.request = async (fn) => {
      if (data.has(fn)) {
        // console.log(`Reusing options for ${fn.name}`)
        // no need to set data, since other async calls would set it
        return await data.get(fn);
      } else {
        const result = fn();
        data.set(fn, result);
        const finishedData = await result;
        data.set(fn, finishedData);
        return finishedData;
      }
    }
    return data;
  }, []);

  const updateNewVersion = () => userContext.updateVersion(newVersion);

  const handleCloseSnackbar = id => () => {
    setMessageQueue(queue => {
      // queue = queue.filter(item => item.visible);
      const idx = queue.findIndex(item => item.id === id);
      queue[idx].visible = false;
      return [...queue];
    });
  };

  const snackbars = useMemo(() => {
    const snacks = [];
    let visibleCounts = 0;
    for (const {type, message, id, visible, buttonText, buttonOnClick} of messageQueue) {
      if (visibleCounts === 3) break;
      if (visible) visibleCounts++;

      snacks.push(
        <Box sx={{display: 'flex', p: 1}} key={id}>
          <Slide direction={visible ? "down" : "left"} in={visible} unmountOnExit>
            <Alert
              style={{zIndex: 1400, alignItems: 'center'}}
              variant="filled"
              onClose={handleCloseSnackbar(id)}
              severity={type}
            >
              {message}
              {buttonOnClick != null && <Button color={"inherit"} onClick={() => {
                handleCloseSnackbar(id);
                buttonOnClick();
              }}>{buttonText}</Button>}
            </Alert>
          </Slide>
        </Box>
      )
    }
    return <Box sx={{display: 'flex', flexDirection: 'column', zIndex: 1400, position: 'fixed', top: 60, right: 20}}>
      {snacks}
    </Box>
  }, [messageQueue]);

  const app = useMemo(() => (
    <div className="App">
      <UserContext.Provider value={userContext}>
        <RouterProvider router={router}/>
      </UserContext.Provider>
    </div>
  ), [userContext]);


  return (
    <ErrorBoundary>
      <OntologyContext.Provider value={ontology}>
        {app}
      </OntologyContext.Provider>
      <Snackbar
        open={!!newVersion}
        message={"A new version is available: " + new Date(Number.parseInt(newVersion) * 1000).toLocaleString()}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={updateNewVersion}
          >
            Reload
          </Button>
        }
      />
      {snackbars}
    </ErrorBoundary>
  );
}

export default App;
