import React, {useContext, useState} from "react";
import {AppBar, Toolbar, Button, Typography, Paper, Tabs, Tab, Box} from "@mui/material";
import {UserContext} from "../../context";
import {useNavigate} from 'react-router-dom';
import {logOut} from '../../api/auth'

export default function Header() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(false);

  const handleLogOut = async () => {
    await logOut();
    userContext.updateUser({username: 'Guest'});
    navigate('/login');
  }

  return (
    <div>
      <AppBar sx={{p: 1.5, bgcolor: 'rgb(0, 23, 81)'}} position="static">
        <Toolbar>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="h4">
              Parcel
            </Typography>
            <Typography variant="subtitle1">
              A Matching platform for building partners
            </Typography>
          </Box>

          {userContext.username === 'Guest' ?
            <Button sx={{color: 'white'}} onClick={() => navigate('/login')}>
              Login
            </Button>
            :
            <React.Fragment>
              <Button sx={{color: 'white'}} onClick={() => navigate('/console/opportunity/search')}>
                Dashboard
              </Button>
              <Button sx={{color: 'white'}} onClick={handleLogOut}>
                Log out
              </Button>
            </React.Fragment>
          }

        </Toolbar>
      </AppBar>

      <Paper square>
        <Tabs
          variant="scrollable"
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newValue) => setTabIndex(newValue)}
        >
          <Tab label="Resources" onClick={() => navigate('/resources')}/>
          <Tab label="Case Studies" onClick={() => navigate('/casestudies')}/>
          <Tab label="Sectors" onClick={() => navigate('/sectors')}/>
          <Tab label="Toolkits" onClick={() => navigate('/toolkits')}/>
        </Tabs>
      </Paper>
    </div>
  )
}
