import { getJson } from "../index";

export const getSpaceTypes = async () => {
  return await getJson('/api/space/types/interest');
};

export const getSpaceTypesForOpportunity = async () => {
  return await getJson('/api/space/types/opportunity');
};

export const getProjectTypes = async () => {
  return await getJson('/api/project/types');
};

export const getProjectStages = async () => {
  return await getJson('/api/project/stages');
};

export const getWards = async () => {
  const data = await getJson('/api/wards');
  const sortedEntries = Object.entries(data).sort((a, b) => {
    const numberA = Number(a[0].slice(5));
    const numberB = Number(b[0].slice(5));
    return numberA - numberB;
  });
  return Object.fromEntries(sortedEntries);
};

export const getLandUseTypes = async () => {
  return await getJson('/api/land_use/types');
};
