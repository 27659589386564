import React from 'react';
import Login from '../components/pages/Auth/Login';
import SignUp from '../components/pages/Auth/SignUp';
import Verify from "../components/pages/Auth/Verify";
import Resources from '../components/pages/Resources';
import CaseStudies from '../components/pages/CaseStudies';
import Sectors from '../components/pages/Sectors';
import Toolkits from '../components/pages/Toolkits';
import Dashboard from '../components/pages/Console/Dashboard';
import ServerStatus from '../components/pages/Console/Admin/ServerStatus';

import OpportunityListingDetail from '../components/pages/Console/Opportunity/DatailPage/ListingDetail';
import FavouriteOpportunities from '../components/pages/Console/Opportunity/Favourite/MyFavourtites';

const SearchOpportunity = React.lazy(() => import(/* webpackChunkName: "search-opportunity" */
  '../components/pages/Console/Opportunity/Search/Search'
  ));
const Post = React.lazy(() => import(/* webpackChunkName: "post-opportunity" */
  '../components/pages/Console/Opportunity/Listing/Post'
  ));
const EditOpportunity = React.lazy(() => import(/* webpackChunkName: "edit-opportunity" */
  '../components/pages/Console/Opportunity/Listing/Edit'
  ));
const SentNotifications = React.lazy(() => import(/* webpackChunkName: "sent-messages" */
  '../components/pages/Console/Message/SentInterests'
  ));
const ViewSentInterest = React.lazy(() => import(/* webpackChunkName: "view-sent-message" */
  '../components/pages/Console/Message/ViewSentInterest'
  ));
const ViewReceivedInterest = React.lazy(() => import(/* webpackChunkName: "view-received-message" */
  '../components/pages/Console/Message/ViewReceivedInterest'
  ));
const ReceivedNotifications = React.lazy(() => import(/* webpackChunkName: "received-messages" */
  '../components/pages/Console/Message/ReceivedInterests'
  ));
const List = React.lazy(() => import(/* webpackChunkName: "list-my-opportunities" */
  '../components/pages/Console/Opportunity/List'
  ));
const Profile = React.lazy(() => import(/* webpackChunkName: "my-profile" */
  '../components/pages/Console/Profile'
  ));

// const OpportunityListingDetail = React.lazy(() => import(/* webpackChunkName: "opportunity-single-page" */
//   '../components/pages/Console/Opportunity/Listing/ListingDetail'
//   ));

const PostNewInterest = React.lazy(() => import(/* webpackChunkName: "post-interest" */
  '../components/pages/Console/Interest/Listing/Post'
  ));

const MyInterestListings = React.lazy(() => import(/* webpackChunkName: "my-interest-listings" */
  '../components/pages/Console/Interest/List'
  ));

const EditInterest = React.lazy(() => import(/* webpackChunkName: "edit-interest" */
  '../components/pages/Console/Interest/Listing/Edit'
  ));

const SearchInterest = React.lazy(() => import(/* webpackChunkName: "search-interest" */
  '../components/pages/Console/Interest/Search/Search'
  ));

export const homeRoutes = [
  {path: '/', exact: true, Component: Login},
  {path: '/login', exact: true, Component: Login},
  {path: '/signup', exact: true, Component: SignUp},
  {path: '/verify/:token', exact: true, Component: Verify},
  {path: '/resources', exact: true, Component: Resources},
  {path: '/casestudies', exact: true, Component: CaseStudies},
  {path: '/sectors', exact: true, Component: Sectors},
  {path: '/toolkits', exact: true, Component: Toolkits},
];

export const consoleRoutes = [
  {path: '', exact: true, Component: Dashboard},
  {path: 'dashboard', exact: true, Component: Dashboard},

  {path: 'opportunity/favourites', exact: true, Component: FavouriteOpportunities},
  {path: 'opportunity/search', exact: true, Component: SearchOpportunity},
  {path: 'opportunity/new', exact: true, Component: Post},
  {path: 'opportunity/details/:id', exact: true, Component: OpportunityListingDetail},
  {path: 'opportunity/:id', exact: true, Component: EditOpportunity},
  {path: 'opportunity', exact: true, Component: List},

  {path: 'interest/search', exact: true, Component: SearchInterest},
  {path: 'interest/new', exact: true, Component: PostNewInterest},
  {path: 'interest/:id', exact: true, Component: EditInterest},
  {path: 'interest', exact: true, Component: MyInterestListings},

  {path: 'profile', exact: true, Component: Profile},
  {path: 'notification/sent', exact: true, Component: SentNotifications},
  {path: 'notification/received', exact: true, Component: ReceivedNotifications},
  {path: 'notification/sent/:id', exact: true, Component: ViewSentInterest},
  {path: 'notification/received/:id', exact: true, Component: ViewReceivedInterest},

  // Admin
  {path: 'admin/status', exact: true, Component: ServerStatus},
];
