import { getJson } from "../index";

export const getServerStatus = async (id, data) => {
  try {
    const res = await getJson(`/api/admin/status`, data);
    if (res.buildTime !== 'N/A')
      res.buildTime = new Date(Number.parseInt(res.buildTime) * 1000).toLocaleString();
    if (res.frontendUpdateTime !== 'N/A')
      res.frontendUpdateTime = new Date(Number.parseInt(res.frontendUpdateTime) * 1000).toLocaleString();
    if (res.docsUpdateTime !== 'N/A')
      res.docsUpdateTime = new Date(Number.parseInt(res.docsUpdateTime) * 1000).toLocaleString();
    return res;
  } catch (e) {
    return {};
  }
};
