import React, { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import { fetchUploadedImage } from "../../api/upload";
import ImageIcon from "@mui/icons-material/Image";
import { cache } from "../../utils/cache/memoryCache";

export default function Image({className, id, alt, onClick}) {
  const [state, setState] = useState({
    objectUrl: null,
  });

  useEffect(() => {
    if (id) {
      if (cache.has(id)) {
        setState({objectUrl: cache.get(id)});
        return;
      }

      const controller = new AbortController();
      (async function () {
        const blob = await fetchUploadedImage(id, controller);
        if (blob) {
          const objectUrl = URL.createObjectURL(blob);
          cache.set(id, objectUrl, blob.size);
          setState({objectUrl});
        }
      })();
      return () => {
        controller.abort();
      }
    }
  }, [id]);

  if (id == null)
    return <div className={className}>
      <ImageIcon style={{fontSize: 60}} color="action" onClick={onClick}/>
    </div>

  if (state.objectUrl)
    return (
      <img className={className} src={state.objectUrl} alt={alt}
           onClick={onClick}/>
    )
  else
    return (
      <div className={className}>
        <Skeleton variant="rectangular" height={170} animation="wave"
                  onClick={onClick}/>
      </div>
    )
}
