export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) || 'Invalid Email';
}

export function validatePostalCode(code) {
  const re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  return re.test(String(code).toLowerCase()) || 'Invalid postal code';
}

export function validatePassword(password) {
  if (password.length <= 7)
    return 'At least 8 characters';

  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
  return re.test(password) ? true : 'At least one number, one lowercase and one uppercase letter';
}

export function validateRepeatPassword(password, repeatedPassword) {
  if (password !== repeatedPassword)
    return 'Passwords are not same';
  return true;
}
