import { validateEmail } from "../../utils/validation/signUpValidator";
import { MultiSelectField } from "../common";
import PhoneInput from '../common/PhoneInput';
import { Divider, FormLabel, Grid, Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from "react";
import { useInputs } from "../../utils/inputsBuilder";
import { getPartnershipRoles } from "../../api/partnership/partnershipRole";
import { getSpaceTypes } from "../../api/project";
import { getUserProfile } from "../../api/auth";
import { Loading } from "../common";

export const defaultInputs = {
  'primary_contact.first_name': {
    label: 'First Name',
  },
  'primary_contact.last_name': {
    label: 'Last Name',
  },
  'primary_contact.position': {
    label: 'Position',
    required: false,
  },
  'primary_contact.email': {
    label: 'Email',
    type: 'email',
    validator: validateEmail,
  },
  'primary_contact.telephone': {
    label: 'Telephone',
    component: PhoneInput,
    required: true,
    validator: (value) => {
      if (value.includes('_'))
        return 'This field is required.'
      return true;
    }
  },
  'primary_contact.telephone_ext': {
    label: 'Ext.',
    required: false,
  },
  'partner_types': {
    label: 'Partner Types',
    required: false,
    component: MultiSelectField,
    options: getPartnershipRoles,
  },
  'spaces_need': {
    label: 'Space Needs',
    required: false,
    component: MultiSelectField,
    options: getSpaceTypes,
  },
  'brief_description': {
    label: 'Brief Description',
    multiline: true,
    required: false,
    rows: 8,
  },
  'same_primary_contact': {
    value: true,
    hidden: true,
  }
};

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  label: {
    color: theme.palette.mode === 'light' && '#5c5c5c',
    fontWeight: 500,
    fontSize: 20,
    marginTop: 16,
    marginBottom: 16,
  },
  textArea: {
    overflowY: "scroll"
  }
}));

export function useNotificationOfInterestForm({mode = 'create', formData}) {

  const classes = useStyles();

  const inputs = useMemo(() => {
    const inputs = {
      ...defaultInputs
    };
    for (const key of Object.keys(inputs)) {
      if (key.startsWith('primary_contact'))
        inputs[key].disabled = mode === 'create';
      inputs[key].notEditable = mode === 'view';
    }
    // Set scroll bar always visible
    inputs.brief_description.inputProps = {className: classes.textArea};
    return inputs;
  }, [mode, classes]);

  const [errors, setErrors] = useState({});
  const [samePrimaryContact, setSamePrimaryContact] = useState(true);
  const [accountPrimaryContact, setAccountPrimaryContact] = useState({});
  const [loading, setLoading] = useState(true);

  const [inputComponents, state, checkAll] = useInputs(inputs, formData, errors);

  useEffect(() => {
    let active = true;

    if (mode === 'create') {
      getUserProfile().then(data => {
        if (active) {
          Object.assign(state.primary_contact, data.primary_contact);
          setAccountPrimaryContact(data.primary_contact);
          setLoading(false);
        }
      })
    } else {
      active && setLoading(false);
    }

    return () => {
      active = false;
    }
  }, [mode, state.primary_contact]);


  const handleSamePrimaryContactChange = e => {
    const newState = e.target.checked;
    state.same_primary_contact = newState;

    for (const key of Object.keys(state.primary_contact)) {
      if (inputs['primary_contact.' + key]) {
        inputs['primary_contact.' + key].disabled = newState;
      }
    }
    if (newState) {
      Object.assign(state.primary_contact, accountPrimaryContact);
    }

    setSamePrimaryContact(newState);
  };

  let form;
  if (loading)
    form = <Loading/>;
  else {
    form = <React.Fragment>
      <FormLabel component="legend" className={classes.label}>Primary Contact</FormLabel>
      {mode === 'create' && <FormControlLabel
        disabled={mode !== 'create'}
        control={<Checkbox checked={samePrimaryContact} onChange={handleSamePrimaryContactChange}/>}
        label="Same as the primary contact of the current account?"
      />}

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={4}>
          {inputComponents['primary_contact.first_name']}
        </Grid>

        <Grid item xs={12} md={4}>
          {inputComponents['primary_contact.last_name']}
        </Grid>

        <Grid item xs={12} md={4}>
          {inputComponents['primary_contact.position']}
        </Grid>

        <Grid item xs={12} md={6}>
          {inputComponents['primary_contact.email']}
        </Grid>

        <Grid item xs={8} md={4}>
          {inputComponents['primary_contact.telephone']}
        </Grid>
        <Grid item xs={4} md={2}>
          {inputComponents['primary_contact.telephone_ext']}
        </Grid>
      </Grid>

      <Divider className={classes.divider}/>
      <FormLabel component="legend" className={classes.label}>Information</FormLabel>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          {inputComponents['partner_types']}
        </Grid>
        <Grid item xs={12} md={6}>
          {inputComponents['spaces_need']}
        </Grid>
        <Grid item xs={12} md={12}>
          {inputComponents['brief_description']}
        </Grid>
      </Grid>
    </React.Fragment>;
  }

  return [form, state, checkAll, setErrors]
}
