import React, { useState } from "react";
import {TextField as MuiTextField } from "@mui/material";

export default function TextField({validator, forwardRef, ...props}) {
  const [error, setError] = useState('');
  const handleBlur = () => {
    if (props.required && props.value === '')
      setError('This field is required.')
    else if (validator) {
      const msg = validator();
      if (msg !== true) {
        setError(msg)
      } else {
        setError('')
      }
    }
    else {
      setError('')
    }
  };

  return <MuiTextField
    fullWidth
    variant="outlined"
    type="text"
    onBlur={handleBlur}
    {...props}
    error={props.error || !!error}
    helperText={(!error && props.helperText) ? props.helperText : error}
    ref={forwardRef}
  />
}
