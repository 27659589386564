import { validateEmail, validatePassword, validateRepeatPassword } from "../../utils/validation/signUpValidator";
import { Loading, MultiSelectField } from "../common";
import PhoneInput from '../common/PhoneInput';
import { Divider, FormLabel, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useState } from "react";
import { useInputs } from "../../utils/inputsBuilder";
import { getOrganizationTypes } from "../../api/organization/organizationType";
import {validatePostalCode} from '../../utils/validation/signUpValidator';

export const organizationInputs = {
  username: {
    label: 'Username',
  },
  organization_name: {
    label: 'Organization Name (Full Legal Name)',
    helperText: 'i.e. Corporation of the City of Toronto, the Governing Council of the University of Toronto'
  },
  password: {
    label: 'Password',
    type: 'password',
    validator: validatePassword,
    checkAfterSuccess: ['repeat_password']
  },
  repeat_password: {
    label: 'Repeat Password',
    type: 'password',
    validator: validateRepeatPassword,
    validatorParams: ['password', 'repeat_password']
  },
  'primary_contact.first_name': {
    label: 'First Name',
  },
  'primary_contact.last_name': {
    label: 'Last Name',
  },
  'primary_contact.position': {
    label: 'Position',
    required: false,
  },
  'primary_contact.email': {
    label: 'Email',
    type: 'email',
    validator: validateEmail,
  },
  'primary_contact.telephone': {
    label: 'Telephone',
    component: PhoneInput,
    required: true,
    validator: (value) => {
      if (value.includes('_'))
        return 'This field is required.'
      return true;
    }
  },
  'primary_contact.telephone_ext': {
    label: 'Ext.',
    required: false,
  },
  organization_types: {
    component: MultiSelectField,
    label: "Organization Type",
    variant: "outlined",
    options: getOrganizationTypes,
  },
  organization_website: {
    label: 'Organization Website',
    required: false,
  },

  'mailing_address.street_number': {
    label: 'Street Number',
  },
  'mailing_address.street_name': {
    label: 'Street Name'
  },
  'mailing_address.city': {
    label: 'City'
  },
  'mailing_address.postal_code': {
    label: 'Postal Code',
    validator: validatePostalCode
  },
  'mailing_address.country': {
    label: 'Country',
    value: 'Canada'
  },
  'mailing_address.province': {
    label: 'Province'
  },
};

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  label: {
    color: theme.palette.mode === 'light' && '#5c5c5c',
    fontWeight: 500,
    fontSize: 20,
    marginTop: 16,
    marginBottom: 16,
  },
}));

export function useOrganizationForm({mode = 'create', formData}) {
  const inputs = useMemo(() => {
    const inputs = {
      ...organizationInputs,
    };
    if (mode === 'edit') {
      delete inputs.password;
      delete inputs.repeat_password;
      inputs.username.disabled = true;
      delete inputs.organization_name.helperText;
    } else {
      inputs.username.disabled = false;
    }
    return inputs;
  }, [mode])

  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [inputComponents, state, checkAll] = useInputs(inputs, formData, errors);

  let form;
  if (Object.keys(formData).length === 0 && mode === 'edit')
    form = <Loading/>;
  else {
    form = <React.Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={5}>
          {inputComponents.username}
        </Grid>
        <Grid item xs={12} md={5}>
          {inputComponents.organization_name}
        </Grid>
        {mode === 'create' &&
        <>
          <Grid item xs={12} md={5}>
            {inputComponents.password}
          </Grid>
          <Grid item xs={12} md={5}>
            {inputComponents.repeat_password}
          </Grid>
        </>
        }
      </Grid>

      <Divider className={classes.divider}/>
      <FormLabel component="legend" className={classes.label}>Primary Contact</FormLabel>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={4}>
          {inputComponents['primary_contact.first_name']}
        </Grid>

        <Grid item xs={12} md={4}>
          {inputComponents['primary_contact.last_name']}
        </Grid>

        <Grid item xs={12} md={4}>
          {inputComponents['primary_contact.position']}
        </Grid>

        <Grid item xs={12} md={6}>
          {inputComponents['primary_contact.email']}
        </Grid>

        <Grid item xs={8} md={4}>
          {inputComponents['primary_contact.telephone']}
        </Grid>
        <Grid item xs={4} md={2}>
          {inputComponents['primary_contact.telephone_ext']}
        </Grid>
      </Grid>

      <Divider className={classes.divider}/>
      <FormLabel component="legend" className={classes.label}>Mailing Address</FormLabel>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          {inputComponents['mailing_address.street_number']}
        </Grid>
        <Grid item xs={12} sm={8}>
          {inputComponents['mailing_address.street_name']}
        </Grid>
        <Grid item xs={12} sm={3}>
          {inputComponents['mailing_address.city']}
        </Grid>
        <Grid item xs={12} sm={3}>
          {inputComponents['mailing_address.province']}
        </Grid>
        <Grid item xs={12} sm={3}>
          {inputComponents['mailing_address.country']}
        </Grid>
        <Grid item xs={12} sm={3}>
          {inputComponents['mailing_address.postal_code']}
        </Grid>
      </Grid>

      <Divider className={classes.divider}/>
      <FormLabel component="legend" className={classes.label}>Information</FormLabel>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={10}>
          {inputComponents.organization_website}
        </Grid>
        <Grid item xs={12} md={10}>
          {inputComponents.organization_types}
        </Grid>
      </Grid>
    </React.Fragment>;
  }

  return [form, state, checkAll, setErrors]
}
