import React from "react";
import { Button, Container, Typography } from '@mui/material';

/**
 * Error boundary for deployment environment.
 * https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error: error};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState(state => ({...state, errorInfo}))
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container style={{paddingTop: '1vh'}}>
          <Typography variant="h2" color="error">Something went wrong.</Typography>
          <Typography variant="h4" color="error">{this.state.error.toString()}</Typography>
          <Typography color="secondary"
                      style={{whiteSpace: 'pre'}}>{this.state.errorInfo && this.state.errorInfo.componentStack}</Typography>
          <Button style={{marginTop: '10px'}} variant="contained" color="secondary"
                  onClick={() => this.setState(state => ({hasError: false}))}>
            Retry
          </Button>
        </Container>
      )
    }

    return this.props.children;
  }
}
