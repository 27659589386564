import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link, Container, Divider, Paper, Typography } from "@mui/material";
import TextField from "../../common/TextInput";
import { login } from "../../../api/auth";
import { UserContext } from "../../../context";
import { SubmitButton } from '../../common';
import { signUpStyle, useSignUpStyle } from "./SignUp";


export default function Login() {
  const navigate = useNavigate();
  const classes = useSignUpStyle();
  const userContext = useContext(UserContext);

  if (userContext.username !== 'Guest') {
    navigate('/console/opportunity/search');
  }
  const [state, setState] = useState({
    username: '',
    password: '',
    alert: ''
  });
  const [waiting, setWaiting] = useState(false);

  const handleChange = name => e => {
    const value = e.target.value;
    setState(prev => ({...prev, [name]: value}));
  };

  const submit = () => {
    setWaiting(true);
    login({username: state.username, password: state.password})
      .then(result => {
        if (result.success) {
          userContext.updateUser({username: state.username, roles: ['organization']}) // hard coded now
          navigate('/console/opportunity/search');
        } else {
          setWaiting(false);
          setState(state => ({...state, alert: result.message}));
        }
      });
  };

  // Submit the form when enter key presses
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      submit();
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper} elevation={5} onKeyPress={handleKeyPress}>
        <Typography variant="h4" sx={signUpStyle.title}>
          Login
        </Typography>

        <TextField
          label="Username"
          value={state.username}
          onChange={handleChange('username')}
          sx={{minHeight: '80px'}}
        />
        <br/>
        <TextField label="Password"
                   type="password"
                   value={state.password}
                   onChange={handleChange('password')}
                   sx={{minHeight: '80px'}}
                   helperText={state.alert}
                   error={!!state.alert}
        />
        <SubmitButton sx={signUpStyle.button} onClick={submit} loading={waiting}>
          Log in
        </SubmitButton>

        <Divider/>
        <Link variant="body2" sx={signUpStyle.link} onClick={() => navigate('/signup')}>
          Don't have an account? Sign up
        </Link>
        <br/>
        <Link variant="body2" sx={signUpStyle.link}>
          Forgot password?
        </Link>
      </Paper>
    </Container>
  )
}
