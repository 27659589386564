import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { validateNewUser } from "../../../api/auth";

const redirectInterval = 5; // redirects in 5 seconds.

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 30,
    padding: 40,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 14,
      marginTop: 10,
    }
  },
  grid: {
    textAlign: 'center',
  },
  title: {
    color: 'rgb(0,32,81)',
    fontWeight: 400,
  },
}));

export default function Verify() {
  const navigate = useNavigate();
  const {token} = useParams();
  const classes = useStyles();
  const [state, setState] = useState({
    verified: null,
    message: '',
    sec: redirectInterval,
  });

  useEffect(() => {
    validateNewUser(token).then(res => {
      if (res.success)
        setState(state => ({...state, verified: true}));
      else {
        setState(state => ({...state, verified: false, message: res.message}));
      }
    });
  }, [token])

  let content = <React.Fragment>
    <Grid item sm={12}>
      <CircularProgress/>
    </Grid>
    <Grid item sm={12}>
      <Typography variant="h4" className={classes.title}>
        Verifying your account...
      </Typography>
    </Grid>
  </React.Fragment>;

  if (state.verified === true) {
    if (state.sec === 5) {
      const task = setInterval(() => {
        setState(state => ({...state, sec: state.sec - 1}));
      }, 1000);

      setTimeout(() => {
        clearInterval(task);
        navigate('/login');
      }, redirectInterval * 1000);
    }

    content = (
      <Grid item sm={12}>
        <Typography variant="h5" className={classes.title}>
          Your account is verified, redirecting to login page in {state.sec} seconds.
        </Typography>
      </Grid>
    )
  } else if (state.verified === false) {
    content = (
      <Grid item sm={12}>
        <Typography variant="h5" className={classes.title}>
          {state.message}
        </Typography>
      </Grid>
    )
  }

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper} elevation={5}>
        <Grid container justifyContent="flex-start" alignItems="baseline" className={classes.grid}>
          {content}
        </Grid>
      </Paper>
    </Container>
  );
}
