import React from "react";
import { CircularProgress, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
    marginTop: 50,
  }
}));

export function Loading({message = 'Loading Components...'}) {
  const classes = useStyles();
  return (
    <div style={{textAlign: 'center'}}>
      <CircularProgress className={classes.progress}/>
      <Typography variant="subtitle2" color={"textSecondary"}>
        {message}
      </Typography>
    </div>
  );
}
