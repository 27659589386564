import { postJson, getJson, putJson } from "./index";

export const login = async ({username, password}) => {
  return await postJson('/api/login', {username, password});
};

export const signUp = async (data) => {
  return await postJson('/api/register', data);
};

export const logOut = async () => {
  return await postJson('/api/logout');
}

export const validateNewUser = async (token) => {
  return await postJson('/api/register/' + token);
}

export const getUserProfile = async () => {
  return await getJson('/api/users/profile');
}

export const updateUserProfile = async (data) => {
  return await putJson('/api/users/profile', data);
}

/**
 *
 * @param data
 * @return {Promise<{success: boolean, frontendUpdateTime}>}
 */
export const getFrontendUpdateTime = async (data) => {
  return await getJson('/api/update/frontend', data);
}
