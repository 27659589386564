import React from "react";
import { useNavigate } from "react-router-dom";

export default function Page404() {
  const navigate = useNavigate();
  navigate('/login');
  return (
    <h3>Login Required</h3>
  )
}
